import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { customerservice } from "../../../config";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { CloudUpload } from "@mui/icons-material";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import GroupForm from "./form";
const base_url = customerservice.uri;

const ignoreFields = [
  "Id",
  "LoanLimit",
  "FieldOfficerId",
  "ServicedLoans",
  "RegFeePaid",
  "AccountBalance",
  "InActiveOLB",
  "MeetingDay",
  "MeetingTime",
  "SMSReminder",
  "BranchId",
  "RegionId",
  "CompanyId",
  "OverrideDoneBy",
  "LimitOverrideDate",
  "IsApproved",
  "ApprovedBy",
  "UnapprovedBy",
  "DeletedBy",
  "MigratedBy",
  "MigratedDate"
];



const actions = {
  entity: "Groups",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
    modelName: "groupIds", // Specify the model name here
    inputObjectName: "bulkActivateDeactivateGroups",
  },
  ManageGroup: {
    key: "MANAGEGROUP",
    button_name: "Manage Group",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4, 5],
  },
  dataObject: {
    ignoreFields,
    objectName: "allGroups",
    parameters: null,
  },
  showMore: {
    objectname: "groupsByColumnValues",
    inputsubobjectname: "group",
    parameters: "phoneNumber",
    search: true,
  },
};
function GroupsJson() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleOpenModal = () => {
    setDialogOpen(true);
  };

  return (
    <Box m="2px">
      <Box
        m="2px"
        sx={{
          justifyContent: "end",
          alignItems: "center",
          display: "flex",
        }}
      >
        {" "}
        <Typography style={{ color: colors.greenAccent[400], margin: "0 8px" }}>
          Import Group(s)
        </Typography>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUpload />}
          sx={{
            backgroundColor: colors.greenAccent[500],
            borderRadius: "4px",
            m: "10px",
          }}
          onClick={handleOpenModal}
        >
          <Typography style={{ color: colors.primary[400], margin: "0 8px" }}>
            Import
          </Typography>
        </Button>
      </Box>
      <DynamicTableForJson
        title="Groups"
        subtitle="View all Groups, create edit or view details"      
        FormComponent={GroupForm}       
        base_url={base_url}
        actions={actions}
       
      />
    </Box>
  );
}

export default GroupsJson;