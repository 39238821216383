import React from "react";
import { Box } from "@mui/material";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import { setupManagementUrl } from "../../../config";

const base_url = setupManagementUrl.uri;

const CustomSmsRegex = () => {
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    // entity: "RegexcustomSms",

    dataObject: {
      ignoreFields: ["createddate", "createdby", "updateddate", "updatedby"],
      objectName: "getallCustomSmsRegex",
      parameters: "",
    },
    isSubRight: true,
    showStatus: true,
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="PlaceHolders Management"
        subtitle="Manage PlaceHolder Settings"
        // FormComponent={Form}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default CustomSmsRegex;
