import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography, CircularProgress, Card, CardContent, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import AccountTransferForm from "./form";
import { Refresh, Close } from "@mui/icons-material"; // Import Refresh and Close icons
import { customerservice } from "../../../../config";

const base_url = customerservice.uri

const CustomerBalanceTransferPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const resetProcess = () => {
    setSearchTerm("");
    setFilteredCustomer(null);
    setShowForm(false);
    setLoadingSearch(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm.length >= 10) {
        const variations = [
          searchTerm,
          searchTerm.startsWith("0") ? searchTerm.replace(/^0/, "+254") : null,
          searchTerm.startsWith("+254") ? searchTerm.replace(/^\+254/, "0") : null,
        ].filter(Boolean);

        setLoadingSearch(true);

        try {
          let foundCustomer = null;

          for (const number of variations) {
            const query = `
              query {
                customerByMobileNumber(mobileNumber: "${number}") {
                  error
                  results
                }
              }
            `;

            const token = localStorage.getItem("token");
            const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

            const response = await axios.post(
              base_url, // Replace with your GraphQL endpoint
              { query },
              {
                headers: {
                  Authorization: formattedToken,
                  "Content-Type": "application/json",
                },
              }
            );

            const data = response.data?.data?.customerByMobileNumber;

            if (data?.results) {
              const parsedResults = JSON.parse(data?.results);
              if (parsedResults.length) {
                foundCustomer = parsedResults[0];
                break; // Exit the loop if customer is found
              }
            } else if (data?.error) {
              Swal.fire("Error", data.error || "Unexpected error", "error");
              break; // Exit the loop on server-side error
            }
          }

          setFilteredCustomer(foundCustomer);

          if (!foundCustomer) {
            Swal.fire("Customer Not Found", "Customer does not exist with any variations.", "warning");
          }
        } catch (error) {
          Swal.fire("Error", "An error occurred while searching. Please try again.", "error");
          console.error("Error fetching customer by mobile number:", error);
        } finally {
          setLoadingSearch(false);
        }
      } else {
        setFilteredCustomer(null);
        setLoadingSearch(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <Box m="20px">
      <Typography variant="h4" gutterBottom>
        Inter Account Transfer
      </Typography>

      <Box mb={3} display="flex" gap={2} alignItems="center">
        <TextField
          label="Enter Customer Mobile Number"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: '300px', // Custom width
            height: '40px', // Custom height
          }}
        />
        <Button variant="contained" disabled={loadingSearch}>
          {loadingSearch ? <CircularProgress size={24} /> : "Search"}
        </Button>
      </Box>

      {filteredCustomer && (
        <Card variant="outlined" sx={{ mb: 3, p: 2 }}>
          <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" gutterBottom>
                Customer Details
              </Typography>
              <IconButton onClick={resetProcess} color="primary">
                <Refresh />
              </IconButton>
            </Box>
            <Typography><strong>Name:</strong> {`${filteredCustomer.FirstName} ${filteredCustomer.OtherNames}`}</Typography>
            <Typography><strong>Mobile:</strong> {filteredCustomer.MobileNumber}</Typography>
            <Typography><strong>Email:</strong> {filteredCustomer.EmailAddress || "N/A"}</Typography>
            <Typography><strong>Address:</strong> {filteredCustomer.PhysicalAddress || "N/A"}</Typography>
          </CardContent>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => setShowForm(true)}
          >
            Transfer Funds
          </Button>
        </Card>
      )}

      {showForm && filteredCustomer && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Transfer Funds
          </Typography>
          <AccountTransferForm
            data={filteredCustomer}
            onClose={() => setShowForm(false)}
            onAction={() => {
              setFilteredCustomer(null);
              setShowForm(false);
              setSearchTerm("");
            }}
          />
          <Box mt={2}>
            <IconButton onClick={() => setShowForm(false)} color="secondary">
              <Close />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomerBalanceTransferPage;
