import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import DynamicForm from "../../../data/Axios/DynamicForm";
import Swal from "sweetalert2";
import {
  allReportMinorClassifications,
  allReportsMajorClassifications,
} from "../../../data/Axios/queries";
import { reportsManagementUrl } from "../../../config";
import { generateAndExecuteMutation, graphqlQuery } from "../../../data/Axios/DynamicService";

const ReportTypesForm = (props) => {
  const base_url = reportsManagementUrl.uri;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [filteredMinorOptions, setFilteredMinorOptions] = useState([]);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const majorResponse = await graphqlQuery(allReportsMajorClassifications, base_url);
        const minorResponse = await graphqlQuery(allReportMinorClassifications, base_url);
        
        if (majorResponse && majorResponse.results) {
          setMajor(JSON.parse(majorResponse.results));
        }

        if (minorResponse && minorResponse.results) {
          setMinor(JSON.parse(minorResponse.results));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);

  const majorOptions = major.map((item) => ({
    parent_key: item.Id,
    value: item.Id,
    label: item.MajorClassification,
  }));

  const minorOptions = minor.map((item) => ({
    parent_key: item.Id,
    value: item.Id,
    label: item.MinorClassification,
  }));

  const fields = [
    {
      name: "majorClassificationID",
      label: "Major Classification",
      type: "select",
      options: majorOptions,
      required: true,
      parent: "main",
      gridColumn: "span 2",
    },
    {
      name: "minorClassificationID",
      label: "Minor Classification",
      type: "select",
      options: filteredMinorOptions,
      required: true,
      gridColumn: "span 2",
    },
    {
      name: "reportName",
      label: "Report Name",
      type: "text",
      required: true,
      gridColumn: "span 2",
    },
  ];

  const initialValues = {
    majorClassificationID: props.data ? props.data.MajorClassificationID : "",
    minorClassificationID: props.data ? props.data.MinorClassificationID : "",
    minorClassification: props.data ? props.data.MinorClassification : "",
    reportName: props.data ? props.data.ReportName : "",
  };

  const onFieldChange = (field, value) => {
    if (field.name === "majorClassificationID") {
      const filteredOptions = minor
        .filter((item) => item.MajorClassificationID === value)
        .map((item) => ({
          parent_key: item.MajorClassificationID,
          value: item.Id,
          label: item.MinorClassification,
        }));
      setFilteredMinorOptions(filteredOptions);
    }
    return {};
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { majorClassificationID, minorClassificationID, reportName } = values;

      const majorClassification =
        major.find((item) => item.Id === majorClassificationID)?.MajorClassification || "";
      const minorClassification =
        minor.find((item) => item.Id === minorClassificationID)?.MinorClassification || "";

      const mutationData = {
        majorClassificationID,
        minorClassificationID,
        reportName,
        majorClassification,
        minorClassification,
        id: props.isEditing ? props.data.Id : null,
      };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateReportTypes" : "createReportTypes",
        mutationData,
        "result",
        base_url
      );

      if (response.results !== null) {
        props.onClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Report Type ${props.isEditing ? "updated" : "created"} successfully.`,
        }).then(() => {
          props.refetchData();
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setSubmitting(false);
      
    }
  };

  return (
    <DynamicForm
      fields={fields}
      onSubmit={handleSubmit}
      title="Report Types"
      subtitle="Fill in the details for the Report Types"
      initialValues={initialValues}
      onFieldChange={onFieldChange}
    />
  );
};

export default ReportTypesForm;
