import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  useTheme,
  Button,
  CircularProgress,
} from "@mui/material";
import DynamicTableForJson from "../../data/DynamicTable/dynamicTableForJson";
import { tokens } from "../../theme";
import { fetchJsonDynamic } from "../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice } from "../../config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { generateAndExecuteMutation } from "../../data/Axios/DynamicService";

const base_url = customerservice.uri;
const Customer360 = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const customerId = localStorage.getItem("CustomerId");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [Guarantordata, setGuarantorDAta] = useState([]);
  const [Businessdata, setBusinessDAta] = useState([]);
  const [nokData, setnokDAta] = useState([]);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);

  const base_url = customerservice.uri;

  useEffect(() => {
    const variables = {
      id: parseInt(customerId),
    };
    async function fetchData() {
      const data = await fetchJsonDynamic(
        "customersById",
        "customer",
        variables,
        customerservice.uri
      );
      setData(JSON.parse(data.results));
    }
    fetchData();
  }, []); // Empty array ensures it runs only once

  // Mock data

  console.log("data:", data);

  const GuarontorColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "FirstName", headerName: "First Name", width: 130 },
    { field: "OtherNames", headerName: "Last Name", width: 90 },
    { field: "MobileNumber", headerName: "Mobile", width: 90 },
  ];

  const customerPhotos = [
    { id: 1, src: `data:image/png;base64,${data?.[0]?.IdFrontPhoto}`, alt: "ID Front Photo" },
    { id: 2, src: `data:image/png;base64,${data?.[0]?.IdBackPhoto}`, alt: "ID Back Photo" },
    { id: 3, src: `data:image/png;base64,${data?.[0]?.SelfiePhoto}`, alt: "Selfie Photo" },
  ];


  const handleApprove = async (customerId) => {
    try {
      setIsLoadingApprove(true); // Start loading
      const CustomerId = parseInt(customerId);
      const mutationData = {
        customerId: CustomerId,
        isApproved: true,
      }
      // Make the API call to approve the customer
      const response = await  generateAndExecuteMutation(
        "approveRejectCustomer",
        mutationData,
        null,
        base_url, 
 
     );
  
      console.log("Customer approved:", response.data);
  
      // Show success notification
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Customer has been approved successfully.",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error approving customer:", error);
  
      // Show error notification
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to approve the customer. Please try again.",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoadingApprove(false); // Stop loading
    }
  };
  
  const handleReject = async (customerId) => {
    try {
      setIsLoadingReject(true); // Start loading
      const CustomerId = parseInt(customerId);
      const mutationData = {
        customerId: CustomerId,
        isApproved: false,
      }
      // Make the API call to reject the customer
      const response = await generateAndExecuteMutation(
       "approveRejectCustomer",
       mutationData,
       null,
       base_url, 

    );

      console.log("Customer rejected:", response.data);

      // Show success notification
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Customer has been rejected successfully.",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error rejecting customer:", error);

      // Show error notification
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to reject the customer. Please try again.",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoadingReject(false); // Stop loading
    }
  };
  

  return (
    <Box
      sx={{
        padding: 3,
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <Box>
        <Button onClick={() => navigate(-1)} variant="contained">
          Back to Customers
        </Button>
      </Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        gutterBottom
      >
        Customer 360 View
      </Typography>
      {/* Row 1: Photos */}
      <Grid container spacing={2} justifyContent="center">
        {customerPhotos.map((photo) => (
          <Grid item xs={12} sm={4} key={photo.id}>
            <Card elevation={3}>
              <CardContent>
                <Box
                  component="img"
                  src={photo.src}
                  alt={photo.alt}
                  sx={{ width: "100%", height: "100%", borderRadius: "8px", objectFit: "contain" }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* Row 2: Customer Biodata */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Customer Biodata
        </Typography>
        <Card elevation={3} sx={{ padding: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            mb={2}
          >
            {/* Row 1 */}
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                First Name
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.FirstName || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Other Names
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.OtherNames || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Date of Birth
              </Typography>
              <Typography variant="body1">
                {dayjs(data?.[0]?.DateOfBirth).format(" MMM DD, YYYY") ||
                  data?.[0]?.DateOfBirth ||
                  "N/A"}
              </Typography>
            </Box>
          </Box>

          {/* Row 2 */}
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            mb={2}
          >
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Mobile Number
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.MobileNumber || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Email Address
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.EmailAddress || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                ID No / Passport
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.IdNoOrPassport || "N/A"}
              </Typography>
            </Box>
          </Box>

          {/* Row 3 */}
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            mb={2}
          >
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Postal Address
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.PostalAddress || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Physical Address
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.PhysicalAddress || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Years Lived at Residence
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.YearsLivedAtResidence || 0}
              </Typography>
            </Box>
          </Box>

          {/* Row 4 */}
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Loan Limit
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.LoanLimit || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Postal Code
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.PostalCode || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Average Cash Sales
              </Typography>
              <Typography variant="body1">
                {data?.[0]?.AverageCashSales || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: "1 1 30%",
                margin: "8px",
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Active
              </Typography>
              <Typography
                color={data?.[0]?.IsActive ? "green" : "red"}
                variant="body1"
              >
                {data?.[0]?.IsActive ? "Active" : "Inactive"}
              </Typography>
            </Box>
          </Box>
          {/* Buttons: Approve and Reject */}
          <Box display="flex" justifyContent="flex-end" gap={2}>
      <Button
        variant="contained"
        color="success"
        onClick={() => handleApprove(customerId)}
        disabled={isLoadingApprove}
        startIcon={
          isLoadingApprove && <CircularProgress size={20} color="inherit" />
        }
      >
        {isLoadingApprove ? "Approving..." : "Approve"}
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => handleReject(customerId)}
        disabled={isLoadingReject}
        startIcon={
          isLoadingReject && <CircularProgress size={20} color="inherit" />
        }
      >
        {isLoadingReject ? "Rejecting..." : "Reject"}
      </Button>
    </Box>

        </Card>
      </Box>
    </Box>
  );
};

export default Customer360;
