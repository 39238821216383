// src/App.js
import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ApolloProvider } from "@apollo/client";
import ChangePasswordForm from "./scenes/users/changepassword";
import Dashboard from "./scenes/dashboard";
import Modules from "./scenes/RoleManagement/Modules";
import Rights from "./scenes/RoleManagement/Rights";
import RoleGroups from "./scenes/RoleManagement/RoleGroup";
import Role from "./scenes/RoleManagement/Roles";
import AssignRight from "./scenes/RoleManagement/Roles/AssignRoleRights";
import Users from "./scenes/users";
import UsersForm from "./scenes/users/users-form";

// Apollo clients
import {
  createRoleClient,
  createSetupClient,
  createUsersClient,
} from "./config";

import withLayout from "./components/HOCApp";
import BarChart from "./components/BarChart";
import PersonDetails from "./scenes/Profile";
import ForgotPasswordForm from "./scenes/users/ForgotPassword";
import SubRightTable from "./scenes/RoleManagement/SubRight";
import AccountAndFinanceModule from "./scenes/AccountsAndFinanceManagement/Accounts";
import CompanyManagementModule from "./scenes/CompanyManagement/CompanyManagement";
import ChartOfAccounts from "./scenes/AccountsAndFinanceManagement/chartofaccounts";
import McbSwitch from "./scenes/login/mcbswitch";

import Setups from "./scenes/SetupManagement/LeadSetups";
import Expense from "./scenes/Expenses/components/expenses";

import LoginSide from "./scenes/login/LoginPage";
import FixedExpenses from "./scenes/Expenses/components/FixedExpenses";

import AdhocMain from "./scenes/adhoc/adhoc";
import MajorClassification from "./scenes/Reports/MajorClassification";
import MinorClassification from "./scenes/Reports/MinorClassification";
import ReportTypes from "./scenes/Reports/ReportTypes";
import ReportBrowser from "./scenes/Reports/ReportBrowser";
import LandingPage from "./scenes/global/landingpage";
import CustomersJson from "./scenes/Customers/customers/CustomersJson";
import GroupsJson from "./scenes/Customers/groups/GroupsJson";
import ProductsTable from "./scenes/SetupManagement/Products";
import ProductChargesAccountTable from "./scenes/SetupManagement/ProductChargesAccount";
import ProductChargesTable from "./scenes/SetupManagement/ProductCharge";
import HorizontalTab from "./scenes/Customers/CustomerHorizontalTab";
import BookLoan from "./scenes/LoanManagement/BookLoan";
import ROLoanAppraisal from "./scenes/LoanManagement/ApprovalTable";
import SuspendedTransactionsTable from "./scenes/AccountsAndFinanceManagement/Tables/SuspendedTransactions";
import BookGroupLoan from "./scenes/GroupLoanManagement/BookLoan";
import GroupLoanAppraisal from "./scenes/GroupLoanManagement/ApprovalTable";
import GroupDashboard from "./scenes/dashboard/groupDashboard";
import InterestPaymentMethodsTable from "./scenes/SetupManagement/InterestPaymentMethods";
import ReconFileUpload from "./scenes/AccountsAndFinanceManagement/Tables/ManualRecon";
import CollectionAnalysis from "./scenes/dashboard/collectionAnalysis";
import GroupCollectionAnalysis from "./scenes/dashboard/groupCollectionAnalysis";
import PendingDisbursementsTable from "./scenes/LoanManagement/Sababisha";
import ProcessAuthorizationTable from "./scenes/RoleManagement/ProcessAuthorization";
import CustomerBalanceTable from "./scenes/AccountsAndFinanceManagement/Tables/AccountTransfers";
import TransactionsTable from "./scenes/AccountsAndFinanceManagement/Tables/ManageAccountTransfers";
import AnalyticalDashboard from "./scenes/dashboard/AnalyticalDashboard";
import LoanSettings from "./scenes/LoanManagement/LoanSettings";
import SababishaApprovalTable from "./scenes/LoanManagement/Sababisha/Approve";
import CustomerLoanLimitApprovalTable from "./scenes/Customers/customers/ApproveCustomerLoanLimit";
import Customer360 from "./scenes/Customers/customer360";
import MapComponent from "./scenes/Maps/mapComponent";
import LoanSearchPage from "./scenes/LoanManagement/LoanSchedule";
import TermsAndConditions from "./scenes/DocumentManager/TermsandConditions/terms";
import CompanyFiles from "./scenes/DocumentManager/CompanyFiles/companyFiles";
import DisbursmentAnalysis from "./scenes/dashboard/disbursementDashboard";
import GroupDisbursmentAnalysis from "./scenes/dashboard/groupdisbursmentDashboard";
import RescheduleLoan from "./scenes/LoanManagement/LoanReschedule/ReScheduleLoan";
import RescheduleGroupLoan from "./scenes/LoanManagement/LoanReschedule/RescheduleGroupLoan";
import ExcessAmountRecon from "./scenes/LoanManagement/ExcessAmountRecon";
import ReBookLoanUI from "./scenes/LoanManagement/LoanRebooking/ReBookLoanUI";
import RebookGroupLoan from "./scenes/LoanManagement/LoanRebooking/RebookGroupLoanApplication";



/**
 * The main App component that renders different routes based on the user's authentication status.
 * If the user is authenticated, it renders the main application with different routes.
 * If the user is not authenticated, it renders the login page.
 * The App component also handles the user's inactivity by logging them out after a certain
 * amount of time.
 *
 * @param {boolean} isAuthenticated - Whether the user is authenticated or not.
 * @param {function} setIsAuthenticated - A function to set the isAuthenticated state.
 * @param {ReactRouterDOM.Navigate} navigate - The navigate function from React Router DOM.
 * @param {ReactRouterDOM.Location} location - The location object from React Router DOM.
 * @param {ApolloClient} userManagementClient - The Apollo client for user management.
 * @param {ApolloClient} roleManagementClient - The Apollo client for role management.
 * @param {ApolloClient} setupManagementClient - The Apollo client for setup management.
 * @param {ApolloClient} salesManagementClient - The Apollo client for sales management.
 */
function App() {
  const userManagementClient = createUsersClient();
  const roleManagementClient = createRoleClient();
  const setupManagementClient = createSetupClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    /**
     * Checks if the user's token has expired and logs them out if it has.
     * If the user is currently on the login or forgot password page, do nothing.
     * Otherwise, remove the token and expiration time from local storage, set isAuthenticated to false, and navigate to the login page.
     */
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      const expirationTime = localStorage.getItem("tokenExpiration");
      const currentTime = new Date().getTime();

      if (
        !token ||
        !expirationTime ||
        currentTime > parseInt(expirationTime, 10)
      ) {
        // Token is missing or expired, log out the user
        if (
          location.pathname === "/forgot-password" ||
          location.pathname === "/"
        ) {
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          setIsAuthenticated(false);
          navigate("/login");
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute

    // Initial check when component mounts
    checkTokenExpiration();

    return () => clearInterval(interval);
  }, [navigate, setIsAuthenticated, location.pathname]);

  const INACTIVITY_LIMIT = 15 * 60 * 1000; // 15 minutes
  useEffect(() => {
    let inactivityTimer;

    /**
     * Resets the inactivity timer. If the timer is already running, it clears the old timeout and sets a new one. If the timer is not running, it sets a new timeout. When the timeout expires, it logs the user out and navigates them to the login page.
     */
    const resetInactivityTimer = () => {
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
      inactivityTimer = setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        localStorage.clear();
        setIsAuthenticated(false);
        navigate("/login");
      }, INACTIVITY_LIMIT);
    };

    const handleActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    resetInactivityTimer(); // Initialize timer

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
    };
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAuthenticated(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      {/* <Provider store={Reduxstore}> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />{" "}
        <ApolloProvider client={userManagementClient}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/login"
              element={<LoginSide onLogin={() => setIsAuthenticated(true)} />}
            />
            <Route path="/changepassword" element={<ChangePasswordForm />} />
            <Route path="/forgot-password" element={<ForgotPasswordForm />} />
            <Route path="/profile" element={withLayout(PersonDetails)()} />
            <Route path="/MCB-SWITCH" element={<McbSwitch />} />
          </Routes>
        </ApolloProvider>
        {isAuthenticated && (
          <>
            <ApolloProvider client={roleManagementClient}>
              <Routes>
                <Route path="/module" element={withLayout(Modules)()} />
                <Route path="/rights" element={withLayout(Rights)()} />
                <Route path="/role-group" element={withLayout(RoleGroups)()} />
                <Route path="/role" element={withLayout(Role)()} />
                <Route path="/subright" element={withLayout(SubRightTable)()} />
                <Route
                  path="/role-Rights"
                  element={withLayout(AssignRight)()}
                />
                <Route
                  path="/processauthorization"
                  element={withLayout(ProcessAuthorizationTable)()}
                />
                <Route
                  path="/role-Rights"
                  element={withLayout(AssignRight)()}
                />
                <Route
                  path="/processauthorization"
                  element={withLayout(ProcessAuthorizationTable)()}
                />
                {/* Customers -  Groups */}
                <Route
                  path="/customers"
                  element={withLayout(CustomersJson)()}
                />
                <Route path="/groups" element={withLayout(GroupsJson)()} />
                <Route
                  path="/managegroup"
                  element={withLayout(HorizontalTab)()}
                />
                <Route
                  path="/customer-details"
                  element={withLayout(HorizontalTab)()}
                />
                <Route
                  path="/customer-360"
                  element={withLayout(Customer360)()}
                />
                <Route path="/maps" element={withLayout(MapComponent)()} />
                <Route
                  path="/customer-loan-limit"
                  element={withLayout(CustomerLoanLimitApprovalTable)()}
                />
                <Route
                  path="/customer-migration"
                  element={withLayout(AccountAndFinanceModule)()}
                />
                <Route
                  path="/customer-group-migration"
                  element={withLayout(AccountAndFinanceModule)()}
                />
                {/* <Route path="/opo-setups" element={withLayout(Setups)()} /> */}
              </Routes>
            </ApolloProvider>
            <ApolloProvider client={userManagementClient}>
              <Routes>
                <Route path="/users" element={withLayout(Users)()} />
                <Route path="/usersform" element={withLayout(UsersForm)()} />
              </Routes>
            </ApolloProvider>

            <ApolloProvider client={setupManagementClient}>
              <Routes>
                <Route path="/opo-setups" element={withLayout(Setups)()} />
                <Route
                  path="/opo-products"
                  element={withLayout(ProductsTable)()}
                />
                <Route
                  path="/productchargesaccount"
                  element={withLayout(ProductChargesAccountTable)()}
                />
                <Route
                  path="/productcharges"
                  element={withLayout(ProductChargesTable)()}
                />
                <Route
                  path="/interestpaymentmethods"
                  element={withLayout(InterestPaymentMethodsTable)()}
                />
                <Route
                  path="/manage-companies"
                  element={withLayout(CompanyManagementModule)()}
                />
                <Route
                  path="/chart-of-accounts"
                  element={withLayout(AccountAndFinanceModule)()}
                />
                <Route
                  path="/suspended-transactions"
                  element={withLayout(SuspendedTransactionsTable)()}
                />
                <Route
                  path="/recon-fileupload"
                  element={withLayout(ReconFileUpload)()}
                />
                <Route
                  path="/account-transfers"
                  element={withLayout(CustomerBalanceTable)()}
                />
                <Route
                  path="/manage-account-transfers"
                  element={withLayout(TransactionsTable)()}
                />

                <Route path="/expenses" element={withLayout(Expense)()} />
                <Route path="/adhoc" element={withLayout(AdhocMain)()} />
                <Route path="/terms" element={withLayout(TermsAndConditions)()} />
                <Route path="/companyFiles" element={withLayout(CompanyFiles)()} />
                <Route
                  path="/fixed-expenses"
                  element={withLayout(FixedExpenses)()}
                />
              </Routes>
            </ApolloProvider>

            <Routes>
              {/* Loan Management */}
              <Route path="/book-loan" element={withLayout(BookLoan)()} />
              <Route path="/loan-rebooking" element={withLayout(ReBookLoanUI)()} />
              <Route path="/group-loan-rebooking" element={withLayout(RebookGroupLoan)()} />
              <Route path="/reschedule-loan" element={withLayout(RescheduleLoan)()} />
              <Route path="/reschedule-group-loan" element={withLayout(RescheduleGroupLoan)()} />


              <Route
                path="/field-officer-approval"
                element={withLayout(ROLoanAppraisal)()}
              />
              <Route
                path="/region-approval"
                element={withLayout(ROLoanAppraisal)()}
              />
              <Route
                path="/branch-approval"
                element={withLayout(ROLoanAppraisal)()}
              />
              <Route
                path="/company-approval"
                element={withLayout(ROLoanAppraisal)()}
              />
              <Route
                path="/sababisha"
                element={withLayout(PendingDisbursementsTable)()}
              />
              <Route
                path="/sababisha-approval"
                element={withLayout(SababishaApprovalTable)()}
              />
              {/*Group Loan Management */}
              <Route
                path="/book-group-loan"
                element={withLayout(BookGroupLoan)()}
              />
              <Route
                path="/group-field-officer-approval"
                element={withLayout(GroupLoanAppraisal)()}
              />
              <Route
                path="/group-region-approval"
                element={withLayout(GroupLoanAppraisal)()}
              />
              <Route
                path="/group-branch-approval"
                element={withLayout(GroupLoanAppraisal)()}
              />
              <Route
                path="/group-company-approval"
                element={withLayout(GroupLoanAppraisal)()}
              />
              <Route
                path="/loan-settings"
                element={withLayout(LoanSettings)()}
              />
              <Route
                path="/loan-schedules"
                element={withLayout(LoanSearchPage)()}
              />

              {/* Reports */}
              <Route
                path="/reports-major-classification"
                element={withLayout(MajorClassification)()}
              />
              <Route
                path="/reports-minor-classification"
                element={withLayout(MinorClassification)()}
              />
              <Route path="/report-types" element={withLayout(ReportTypes)()} />
              <Route
                path="/report-browser"
                element={withLayout(ReportBrowser)()}
              />
              <Route path="/bar" element={withLayout(BarChart)()} />
              <Route
                path="/super-admin-dashboard"
                element={withLayout(Dashboard)()}
              />
              <Route
                path="/group-dashboard"
                element={withLayout(GroupDashboard)()}
              />
              <Route
                path="/collection-dashboard"
                element={withLayout(CollectionAnalysis)()}
              />
              <Route
                path="/group-collection-dashboard"
                element={withLayout(GroupCollectionAnalysis)()}
              />
              <Route
                path="/analytical-dashboard"
                element={withLayout(AnalyticalDashboard)()}
              />
              <Route
                path="/disbursment-dashboard"
                element={withLayout(DisbursmentAnalysis)()}
              />
              <Route
                path="/group-disbursment-dashboard"
                element={withLayout(GroupDisbursmentAnalysis)()}
              />
              {/* <Route path="/adhoc" element={withLayout(AdhocMain)()} /> */}
            </Routes>
          </>
        )}
      </ThemeProvider>
      {/* </Provider> */}
    </ColorModeContext.Provider>
  );
}

export default App;
