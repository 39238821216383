import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import swal from "sweetalert";
import {
  CREATE_USER,
  UPDATE_USER,
  ACTIVATE_USER,
  DEACTIVATE_USER,
} from "../../data/userData";
import {
  fetchDataEngine,
  generateAndExecuteMutationException,
  graphqlQuery,
} from "../../data/Axios/DynamicService";
import {
  allCompanies,
  allRegions,
  getrolesquery,
  allUsersQuery,
  allGenders,
} from "../../data/Axios/queries";
import {
  companyManagementUrl,
  roleManagemenUrl,
  setupManagementUrl,
  userManagementUrl,
} from "../../config";
import DynamicForm from "../../data/Axios/DynamicForm";
import { usersField } from "../../data/DynamicTable/usersDynamicForms";
import Swal from "sweetalert2";
import {
  executeAnyMutation,
  fetchJson,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";

const base_url = roleManagemenUrl.uri;
const setup_url = companyManagementUrl.uri
const UsersForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const tokenRegionId = parseInt(decodedToken.RegionId);
  const tokenBranchId = parseInt(decodedToken.BranchId);
  const [roleData, setRoleData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [showBranches, setShowBranches] = useState(false);
  const [genderData, setGenderData] = useState([]);
  const [branchoptionsInitialvalue, setBranchOptionsInitialValue] = useState(
    []
  );
  const [branchIdData, setBranchIdData] = useState(null);
  const [regionIdData, setRegionIdData] = useState(null);
  const [showRegionsOnly, setShowRegionsOnly] = useState(false);
  const [selectedRegionId, setSelectedRegionId ] = useState([]);
  const [Mappedbranches, setMappedBranches] = useState([])
  const initialValues = {
    firstName: props.userData ? props.userData.FirstName : "",
    lastName: props.userData ? props.userData.LastName : "",
    email: props.userData ? props.userData.Email : "",
    phone: props.userData ? props.userData.Phone : "",
    username: props.userData ? props.userData.Username : "",
    idno: props.userData ? props.userData.Idno : "",
    roleId: props.userData ? props.userData.RoleId : "",
    roleName: props.userData ? props.userData.RoleName : "",

    genderId: props.userData ? props.userData.GenderId : "",
    regionId: props.userData ? props.userData.RegionId : "",
    branchId: props.userData ? props.userData.BranchId : "",
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        const facts = await graphqlQuery(getrolesquery, base_url);
        if (facts) {
          setRoleData(facts);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchAllData();
  }, []);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const regionResponse = await fetchJson({
          objectName: "region",

          baseUrl: companyManagementUrl.uri,
        });
        const allRegions = JSON.parse(regionResponse.results) || [];

        if (allRegions) {
          setRegionData(allRegions || []);
        }

        // Fetch branch data if userData has regionId
        if (tokenRegionId) {
          const branchResponse = await fetchBranches(tokenRegionId);
          setBranchData(branchResponse || []);
        }

        // Determine visibility of regions and branches
        if (props.userData && props.userData.roleId) {
          handleRoleChange(props.userData.roleId);
        }
        // const genderResponse = await graphqlQuery(
        //   allGenders,
        //   setupManagementUrl.uri
        // );
        // if (genderResponse) {
        //   setGenderData(genderResponse);
        //   console.log(genderResponse);
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, [props.userData]);

  

  const handleFieldChange = async (field, value) => {
    if (field.name === "roleId" ) {
      handleRoleChange(value);
      setSelectedRegionId(value);
    }
    if (field.name === "regionId") {
      console.log("Selected Region ID:", value); // Log the selected region ID
      const branchOptions = await fetchBranches(value);
      console.log("Fetched Branch Options:", branchOptions); // Log the fetched options
      setBranchOptionsInitialValue(branchOptions || []); // Ensure an array is set
      setBranchData(branchOptions); // Set the branch data for further rendering in form
      console.log("Branch Options:", branchOptions); // Log branch options
      setBranchIdData(null); // Reset the branchId data if region changes
      return branchOptions;
    }
  };
  
  const fetchBranches = async (regionId) => {
    try {
      console.log("Fetching branches for regionId:", regionId);
      const params = { regionId: parseInt(regionId) };
      console.log("Params for API call:", params);
  
      const businessDetails = await fetchJsonDynamic(
        "allBranchesByColumnValues",
        "branchModel",
        params,
        setup_url
      );
  
      console.log("API Response:", businessDetails);
  
      const allBranchDetails = JSON.parse(businessDetails.results) || [];
      console.log("Parsed branch details:", allBranchDetails);
  
      const branchOptions = allBranchDetails.map((role) => ({
        parent_key: role.Id,
        value: role.Id,
        label: role.Name,
      }));
  
      console.log("Mapped Branch Options:", branchOptions);
      setMappedBranches(branchOptions)

  
      return branchOptions; // Return the mapped branch options
    } catch (error) {
      console.error("Error fetching branches:", error);
      return [];
    }
  };
  

  const handleRoleChange = (roleId) => {
    const selectedRole = roleData.find((role) => role.id === parseInt(roleId));
    if (selectedRole) {
      const { roleGroupId } = selectedRole;
      const tokenRoleGroupId = parseInt(decodedToken.RoleGroupId);
      if (tokenRoleGroupId === 1 || tokenRoleGroupId === 2) {
        if (roleGroupId === 1 || roleGroupId === 2) {
          setShowRegions(false);
          setShowBranches(false);
          setShowRegionsOnly(false);
        } else if (roleGroupId === 3) {
          setShowRegionsOnly(true);
          setShowRegions(false);
          setShowBranches(false);
        } else if (roleGroupId === 4 || roleGroupId === 5) {
          setShowRegions(true);
          setShowBranches(false);
          setShowRegionsOnly(false);
        }
      } 
      else if (tokenRoleGroupId === 3) {
        if (roleGroupId === 3) {
          setShowRegionsOnly(false);
          setShowRegions(false);
          setShowBranches(false);
          setRegionIdData(tokenRegionId);
        } else if (roleGroupId === 4 || roleGroupId === 5) {
          setShowRegions(false);
          setShowBranches(true);
          setShowRegionsOnly(false);
          setRegionIdData(tokenRegionId);
        }
      } else {
        if (roleGroupId === 4 || roleGroupId === 5) {
          setShowRegions(false);
          setShowBranches(false);
          setShowRegionsOnly(false);
          setBranchIdData(tokenBranchId);

          setRegionIdData(tokenRegionId);
        }
      }
      // if (roleGroupId === 1 || roleGroupId === 2) {
      //   setShowRegions(false);
      //   setShowBranches(false);
      // } else if (roleGroupId === 3) {
      //   setShowRegions(false);
      //   setShowBranches(true);
      // } else if (roleGroupId === 4) {
      //   setShowRegions(false);
      //   setShowBranches(false);
      // }
    }
  };
  // const companyOptions = companyData
  //   ? companyData.map((company) => ({
  //       value: company.id,
  //       label: company.companyName,
  //     }))
  //   : [];

  const roleOptions = roleData
    ? roleData.map((role) => ({
        parent_key: role.id,
        value: role.id,
        label: role.role,
      }))
    : [];
  const regionOptions = regionData
    ? regionData.map((role) => ({
        parent_key: role.Id,
        value: role.Id,
        label: role.Name,
      }))
    : [];

  // const genderOptions = genderData
  //   ? genderData.map((user) => ({
  //       parent_key: user.id,
  //       value: user.id,
  //       label: user.gender,
  //     }))
  //   : [];
  // const branchOptions = branchData
  //   ? branchData.map((user) => ({
  //       value: user.id,
  //       label: user.branchName,
  //     }))
  //   : [];
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        firstName,
        lastName,
        email,
        phone,
        username,
        idno,
        roleId,
        gender,
        regionId,
        branchId,
      } = values;

      const mutationData = {
        firstName,
        lastName,
        email,
        phone,
        username,
        idno,
        branchId: branchId || branchIdData || null,
        regionId: regionId || regionIdData || null,
        roleId: parseInt(roleId),

        createdBy: userId,
      };

      if (props.isEditing) {
        mutationData.id = props.userData.Id;
      }

      const response = await executeAnyMutation(
        props.isEditing ? "updateUser" : "createUser",
        mutationData,
        "user",
        false,
        userManagementUrl.uri
      );

      if (response.results) {
        props.onClose();
        props.refetch();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `User ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      } else if (response.error) {
        const errorObj = JSON.parse(response.error);
        const errorMessage = errorObj?.Error?.match(/System\.Exception: (.*?)\./)?.[1]; // Extract the error message
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessage || "An error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const fields = [
    ...usersField,

    {
      name: "roleId",
      label: "Role *",
      type: "select",
      options: roleData
        ? roleData.map((role) => ({
            value: role.id,
            label: role.role,
          }))
        : [],
      gridColumn: "span 2",
      required: true,
      parent: "main",
    },
    ...(showRegionsOnly
      ? [
          {
            name: "regionId",
            label: "Region *",
            type: "select",
            options: regionData
              ? regionData.map((region) => ({
                  value: region.Id,
                  label: region.Name,
                }))
              : [],
            gridColumn: "span 2",
            //parent: "main",
          },
        ]
      : []),
    ...(showRegions
       ? [
          {
            name: "regionId",
            label: "Region *",
            type: "select",
            options: regionData
              ? regionData.map((region) => ({
                  value: region.Id,
                  label: region.Name,
                }))
              : [],
            gridColumn: "span 2",
            parent: "main",
          },
          {
            name: "branchId",
            label: "Branch *",
            type: "select",
            options: Mappedbranches,
            gridColumn: "span 2",
            required: true,
            parent: "dependent",
            dependentOn: "regionId",
          },
        ]
      : []),
    ...(showBranches
      ? [
          {
            name: "branchId",
            label: "Branch *",
            type: "select",
            options: Mappedbranches,
            gridColumn: "span 2",
            required: true,
            // parent: "dependent",
            // dependentOn: "regionId",
          },
        ]
      : []),
  ];

  return (
    <div>
      <DynamicForm
        initialValues={initialValues}
        fields={fields}
        onFieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        title={props.isEditing ? "Edit User" : "Create User"}
        subtitle={
          props.isEditing ? "Edit an Existing User" : "Create a New User"
        }
      />
    </div>
  );
};

export default UsersForm;
