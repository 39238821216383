import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  customerservice,
  loanManagementUrl,
  setupManagementUrl,
} from "../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";
import { CircularProgress } from "@mui/material";


const BookLoan = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  // const customersData = [
  //   { id: '36991073', name: 'Lilian Kilani', email: 'fulusi@gmail.com', phone: '0798706353', branch: 'Kitengela', loanLimit: 3000, createdAt: 'Nov 29, 2021' },
  //   // Add more customer data as needed
  // ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState("");
  const [customers, setCustomers] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const resetForm = () => {
    setSearchTerm("");
    setFilteredCustomer(null);
    setAmount("");
    setLoanProductSelected("");
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        if (/^\d+$/.test(searchTerm) || searchTerm.startsWith("+254")) {
          // Keep loading until a valid length is reached
          setLoadingSearch(searchTerm.length < 10);
  
          // Process search when 10 or more digits (or a valid international format) are entered
          if (searchTerm.length >= 10) {
            // Normalize inputs for comparison
            const normalizedSearchTerm =
              searchTerm.startsWith("0")
                ? searchTerm.replace(/^0/, "+254")
                : searchTerm.startsWith("+254")
                ? searchTerm.replace(/^\+254/, "0")
                : searchTerm;
  
            // Search by MobileNumber or IdNoOrPassport
            const customer = customers.find(
              (cust) =>
                cust.MobileNumber.includes(searchTerm || '') || // Exact match with entered search term
                cust.MobileNumber.includes(normalizedSearchTerm || '') || // Match normalized term
                cust.IdNoOrPassport.includes(searchTerm || '') // Independent IdNoOrPassport condition
            );
  
            setFilteredCustomer(customer || null);
            setLoadingSearch(false); // End loading after search is done
  
            if (!customer) {
              Swal.fire("Customer Not Found", "Customer does not exist", "warning");
            }
          }
        } else {
          setLoadingSearch(true); // Keep loading for non-numeric inputs
          setFilteredCustomer(null);
        }
      } else {
        setFilteredCustomer(null);
        setLoadingSearch(false); // Stop loading if the input is cleared
      }
    }, 500); // Short debounce delay for a smoother typing experience
  
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  
  
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchJsonDynamic(
        "allCustomers",
        null,
        null,
        customerservice.uri
      );
      const allCustomers = JSON.parse(response.results) || [];

      const params = {companyId: companyId};
      const productResponse = await fetchJsonDynamic(
        "allProductsByColumnValues",
        "parameters",
        params,
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];
      // Filter regions based on selected company ID

      setCustomers(allCustomers);
      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);
  const handleAppraisalSubmit = async () => {
    setLoadingSubmit(true); // Start loading when the form is submitted

    try {
      const mutationData = {
        p_CustomerId: filteredCustomer.Id,
        p_Principle: parseInt(amount),
        p_ProductId: loanProductSelected,
      };

      const response = await executeAnyMutation(
        "insertLoanApplications",
        mutationData,
        "loanApplication",
        false,
        loanManagementUrl.uri
      );
      const parsedResults = JSON.parse(response.results);
      if (parsedResults) {
        if (parsedResults === "Loan Booked Successfully") {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: parsedResults,
          });
              // Reset form upon successful submission
              resetForm();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: parsedResults,
          });
        }
      } else if (response.errors) {
        throw new Error(
          response.errors[0].Error || "An internal server error occurred."
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
      resetForm();
    } finally {
      setLoadingSubmit(false); // End loading after the process
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Book A Loan</Typography>
      <TextField
        label="Search Customer by customer phone number | ID No. | Passport"
        placeholder="Enter search criteria"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        //onChange={handleSearchChange}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: loadingSearch && (
            <CircularProgress size={24} sx={{ marginRight: 2 }} />
          ),
        }}
      />

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Customer Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Customer Name:</strong> {filteredCustomer.FirstName}{" "}
                    {filteredCustomer.OtherNames}{" "}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {filteredCustomer.EmailAddress}
                  </Typography>
                  <Typography>
                    <strong>ID Number:</strong>{" "}
                    {filteredCustomer.IdNoOrPassport}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Mobile Number:</strong>{" "}
                    {filteredCustomer.MobileNumber}
                  </Typography>
                  <Typography>
                    <strong>Branch:</strong> {filteredCustomer.BranchId}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer.DateCreated}
                  </Typography>
                  <Typography>
                    <strong>Current Loan Limit:</strong>{" "}
                    {filteredCustomer.LoanLimit || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Loan Product</InputLabel>
                    <Select
                      value={loanProductSelected}
                      onChange={(e) => setLoanProductSelected(e.target.value)}
                      defaultValue=""
                    >
                      <MenuItem value="" disabled>
                        Select Loan Product
                      </MenuItem>
                      {loanProduct.map((company) => (
                        <MenuItem key={company.Id} value={company.Id}>
                          {company.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Amount to Disburse"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleAppraisalSubmit}
            disabled={loadingSubmit} // Disable button when loading

          >
            {loadingSubmit ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit For Appraisal"
            )}
          </Button>
        </>
      )}
    </Box>
  );
};

export default BookLoan;