import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import FixedExpenseForm from "./FixedExpenseform";
import {companyManagementUrl, salesManagement } from "../../../config";
import ExpenseForm from "./Expenseform";


const base_url = companyManagementUrl.uri;


  const actions = {
    entity: "FixedExpenses",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Activate/Deactivate",
      inputObjectName: "bulkActivateDeactivateFixedEntries",
      modelName:"fixedEntryIds",
      Show_Button: true,
      permission: "ActivatePermission",
    },
    AssignTask: {
      key: "ASSIGN_TASK",
      button_name: "Assign Task",
      Show_Button: false,
      permission: "hasNoPermission",
      roleGroupId: [1, 2],
    },
    dataObject: {
      // ignoreFields,
      objectName: "allExpensesEntriesView",
      parameters: "",
    },
    showStatus: true,
  };
  const Expenses = () => {
    const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
    const userId = parseInt(decodedToken.Id);
    const companyId = parseInt(decodedToken.CompanyId);

  return (
    <Box m="20px">
    <>
      <DynamicTableForJson
        title="Expenses"
        subtitle="Manage Expenses"
        FormComponent={ExpenseForm}
        base_url={base_url}
        actions={actions}
      />
      </>
    </Box>
  );
};

export default Expenses;
