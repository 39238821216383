import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import {
  customerservice,
  loanManagementUrl,
  setupManagementUrl,
} from "../../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
  fetchJson,
} from "../../../data/DynamicServiceNew/jsondynamicservice";
import { graphqlQuery } from "../../../data/Axios/DynamicService";
import CustomTable from "../../../data/customtable";
import { tokens } from "../../../theme";

const RescheduleLoan = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const customersData = [
  //   { id: '36991073', name: 'Lilian Kilani', email: 'fulusi@gmail.com', phone: '0798706353', branch: 'Kitengela', loanLimit: 3000, createdAt: 'Nov 29, 2021' },
  //   // Add more customer data as needed
  // ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [customers, setCustomers] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loanscheduledata, setLoanscheduledata] = useState([]);
  const [loanProduct, setLoanProduct] = useState([]);

  const resetForm = () => {
    setSearchTerm("");
    setFilteredCustomer(null);
    setAmount("");
    setLoanProductSelected("");
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = { companyId: companyId };
      const productResponse = await fetchJsonDynamic(
        "products",
        null,
        null,
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];
      // Filter regions based on selected company ID

      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);

  const handlesearch = async () => {
    setLoadingSearch(true); // Start loading

    try {
      const mutationdata = { mobileNumber: searchTerm };

      // Perform API call to search customer
      const customer = await fetchJson({
        objectName: "allLoanSchedulesByMobileNumber",
        inputObjectName: "parameters",
        params: mutationdata,
        baseUrl: loanManagementUrl.uri,
      });

      const result = JSON.parse(customer.results);

      if (result === null || result.length === 0) {
        Swal.fire("Sorry!", `${customer.error}`, "warning");
      } else {
        setFilteredCustomer(result.LoanApplications || null); // Update state with customer data
        setLoanscheduledata(result.LoanSchedules || []);
      }
    } catch (error) {
      console.error("Search error:", error);
      Swal.fire(
        "Search Failed",
        "An error occurred during the search. Please try again later.",
        "error"
      );
    } finally {
      setLoadingSearch(false); // End loading after search is completed
    }
  }; // Short debounce delay for a smoother typing experience

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAppraisalSubmit = async () => {
    setLoadingSubmit(true); // Start loading when the form is submitted

    try {
      const mutationData = {
        p_loanid: filteredCustomer[0].Id,
        p_productid: loanProductSelected,
      };

      const response = await executeAnyMutation(
        "rescheduleLoan",
        mutationData,
        "loanReschedule",
        false,
        loanManagementUrl.uri
      );
      const parsedResults = JSON.parse(response.results);
      if (parsedResults !== null) {
        if (parsedResults === 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Loan Rescheduled Successfully!",
          });
          // Reset form upon successful submission
          handlesearch();
        } else if (parsedResults === 0) {
          Swal.fire({
            icon: "warning",
            title: "Rescheduling failed!",
            text: "Not eligible for rescheduling!",
          });
        } else if (parsedResults === -1) {
          Swal.fire({
            icon: "error",
            title: "Rescheduling failed!",
            text: "Loan Partially paid therefore not eligible for rescheduling!",
          });
        } else if (parsedResults === -2) {
          Swal.fire({
            icon: "warning",
            title: "Rescheduling failed!",
            text: "Product contain no Installments!",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Unsuccessfull Loan Rescheduling!",
            text: parsedResults,
          });
        }
      } else if (response.errors) {
        throw new Error(
          response.errors[0].Error || "An internal server error occurred."
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
      resetForm();
    } finally {
      setLoadingSubmit(false); // End loading after the process
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Re-Schedule Loan</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            label="Search Loan by customer phone number | ID No. | Passport"
            placeholder="Enter search criteria"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: loadingSearch && (
                <CircularProgress size={24} sx={{ marginRight: 2 }} />
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlesearch}
            disabled={loadingSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent sx={{ bgcolor: `${colors.primary[400]} !important` }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: `${colors.blueAccent[700]} !important`,
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Loan Id:</strong> {filteredCustomer[0].Id}
                  </Typography>
                  <Typography>
                    <strong>Amount To disburse:</strong>{" "}
                    {filteredCustomer[0].AmountToDisburse}
                  </Typography>
                  <Typography>
                    <strong>Customer Phone:</strong>{" "}
                    {filteredCustomer[0].CustomerPhone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Application Date:</strong>{" "}
                    {filteredCustomer[0].ApplicationDate
                      ? new Date(
                          filteredCustomer[0].ApplicationDate
                        ).toLocaleDateString("en-Us", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Company Comments:</strong>{" "}
                    {filteredCustomer[0].CompanyComments}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer[0].DateCreated
                      ? new Date(
                          filteredCustomer[0].DateCreated
                        ).toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Expected Clear Date:</strong>{" "}
                    {filteredCustomer[0].expectedduedate
                      ? new Date(
                          filteredCustomer[0].expectedduedate
                        ).toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent sx={{ bgcolor: `${colors.primary[400]} !important` }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: `${colors.blueAccent[700]} !important`,
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                  ml: 3,
                  mr: 2,
                }}
              >
                Reschedule Loan
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Select (Loan Product) */}
                <Grid item xs={12} sm={8}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ mt: 3, ml: 3 }}>Loan Product</InputLabel>
                    <Select
                      value={loanProductSelected}
                      onChange={(e) => setLoanProductSelected(e.target.value)}
                      defaultValue=""
                      sx={{ mt: 3, ml: 3 }}
                    >
                      <MenuItem value="" disabled>
                        Select Loan Product
                      </MenuItem>
                      {loanProduct.map((company) => (
                        <MenuItem key={company.Id} value={company.Id}>
                          {company.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Button (Reschedule Loan) */}
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2, mr: 1 }}
                    onClick={handleAppraisalSubmit}
                    disabled={loadingSubmit}
                  >
                    {loadingSubmit ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Reschedule Loan"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent sx={{ bgcolor: `${colors.primary[400]} !important` }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: `${colors.blueAccent[700]} !important`,
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                  ml: 3,
                  mr: 2,
                }}
              >
                Loan Schedules
              </Typography>
            </CardContent>
            <CardContent sx={{ bgcolor: `${colors.primary[400]} !important` }}>
              <CustomTable data={loanscheduledata} />
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
};

export default RescheduleLoan;
