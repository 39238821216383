import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Box, Button, TextField, Select, MenuItem, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import Header from "../../../components/Header";
import { addFixedExpense, updateFixedExpense, fetchIntervals, fetchAccountSubCategory } from "../../../data/expensesData";
import { generateAndExecuteMutation, graphqlQuery } from "../../../data/Axios/DynamicService";
import { AccountsandFinanceurl, companyManagementUrl, salesManagement } from "../../../config";
//import { FixedExpenseFields } from "../../../data/DynamicTable/AccountsDynamicForms";
import DynamicForm from "../../../data/Axios/DynamicForm";
import { fetchJsonDynamic } from "../../../data/DynamicServiceNew/jsondynamicservice";


const base_url = companyManagementUrl.uri;

const ExpenseForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const [intervals, setIntervals] = useState([]);
  const [accountSubcategories, setAccountSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    id: props.data ? props.data.id : null,
    expenseName: props.data ? props.data.expenseName : "",
    amount: props.data ? props.data.amount : "",
    description: props.data ? props.data.description : "",
    intervalId: props.data ? props.data.intervalId : "",
    accountSubcategoryId: props.data ? props.data.accountSubcategoryId : "",
  });

  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);

  // Fetching intervals and account subcategories
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const intervalsData = await fetchIntervals();
        const accountSubcategoriesData =await fetchJsonDynamic(
        "accountSubCategory",
        null,
        null,
        AccountsandFinanceurl.uri
      );
      if (accountSubcategoriesData?.results) {
        const results = JSON.parse(accountSubcategoriesData?.results);
      
        // // Filter active subcategories
        // const UniqueSubcategories = results.filter(
        //   (subcategory) => subcategory.accountCategoryId === 2
        // );
        setAccountSubcategories(results);

      }
        setIntervals(intervalsData);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      } finally {
        setLoading(false);  // Ensure loading is set to false after data fetching
      }
    };

    fetchDropdownData();
  }, []);

  const initialValues = {
    id: props.data ? props.data.id : null,
    expenseName: props.data ? props.data.expenseName : "",
    amount: props.data ? props.data.amount : "",
    description: props.data ? props.data.description : "",
    // intervalId: props.data ? props.data.intervalId : "",
    accountSubCategoryId: props.data ? props.data.accountSubCategoryId : "",
  };

  const validationSchema = yup.object().shape({
    expenseName: yup.string().required("Expense Name is required"),
    amount: yup.number().required("Amount is required").min(0, "Amount cannot be negative"),
    description: yup.string().required("Description is required"),
    //intervalId: yup.string().required("Interval is required"),
    accountSubcategoryId: yup.string().required("Account Type is required"),
  });

  const handleFieldChange = async (field, value) => {
    setFormData({ ...initialValues, [field.name]: value });
  };

  const fields = [
    {
      name: "expenseName",
      label: "Expense Name",
      type: "text",
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "amount",
      label: "Amount",
      type: "number",
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "accountSubCategoryId",
      label: "Account Type",
      type: "select",
      gridColumn: "span 2",
      required: true,
      options: accountSubcategories.map((subcategory) => ({
        value: subcategory.Id,
        label: subcategory.AccountName,
      })),
    },
  ];

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { expenseName,amount,  description, intervalId, accountSubCategoryId } = values;
      
      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            expenseName,
            amount: parseFloat(amount),
            description,
            //intervalId,
            accountSubCategoryId,
            updatedBy: userId,
          }
        : {
            expenseName,
            amount: parseFloat(amount),
            description,
            //intervalId,
            accountSubCategoryId,
            createdBy: userId,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateExpensesEntries" : "createExpensesEntries",
        mutationData,
        "expensesEntries",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Expense ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  if (loading) {
    return <div className="justify-content-center"><CircularProgress /></div>; // Use CircularProgress for better UX
  }

  return (
    <>
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onFieldChange={handleFieldChange}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Expense" : "Create Expense"}
      subtitle={props.isEditing ? "Edit an existing Expense" : "Create new Expense"}
    />
    </>
  );
};

export default ExpenseForm;
