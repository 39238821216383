import React, { useState } from "react";
import {
  Card,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { reportsManagementUrl } from "../../../config";
import { generateReport } from "../../../data/DynamicTable/DynamicFunctions";
import { tokens } from "../../../theme";

const ReportCards = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  //reports without time
  const handleGenerateReport = async (reportTypeID, ReportType) => {
    try {
      setIsLoading(true);
      const mutationData = {
        ReportTypeId: reportTypeID,
        ReportType: ReportType,
      };
      const newTab = window.open("", "_blank");
      newTab.document.write(`
        <html>
          <head>
            <title>Loading...</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
                background-color: #f0f0f0;
              }
              .loading {
                font-size: 24px;
                color: #333;
              }
            </style>
          </head>
          <body>
            <div class="loading">Loading, please wait...</div>
          </body>
        </html>
      `);
      newTab.document.close();
      const response = await generateReport(
        mutationData,
        reportsManagementUrl.generate_report_uri,
        setIsLoading
      );
      if (response.ok) {
        const htmlContent = await response.text();
        newTab.document.open();
        newTab.document.write(htmlContent);
        newTab.document.close();
      } else {
        newTab.close();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateReportWithTime = async (reportTypeID, ReportType) => {
    try {
      const mutationData = {
        ReportTypeId: reportTypeID,
        ReportType: ReportType,
        StartDate: startDate,
        EndDate: endDate,
      };
      const newTab = window.open("", "_blank");
      newTab.document.write(`
        <html>
          <head>
            <title>Loading...</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
                background-color: #f0f0f0;
              }
              .loading {
                font-size: 24px;
                color: #333;
              }
            </style>
          </head>
          <body>
            <div class="loading">Loading, please wait...</div>
          </body>
        </html>
      `);
      newTab.document.close();
      const response = await generateReport(
        mutationData,
        reportsManagementUrl.generate_report_uri,
        setIsLoading
      );
      if (response.ok) {
        const htmlContent = await response.text();
        newTab.document.open();
        newTab.document.write(htmlContent);
        newTab.document.close();
      } else {
        newTab.close();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Typography
        variant="h4" // Increase the font size (h4 is larger than h6)
        gutterBottom
        sx={{
          fontWeight: "bold",
          marginTop: "16px",
          textAlign: "center", // Center the text
        }}
      >
        Quick Reports
      </Typography>

      <Box
        display="grid"
        gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
        gap={2}
        mt={4}
      >
        {[
          { title: "Get All Users Report", id: 2},
          { title: "All Customers Report", id: 22 },
          { title: "New Customers Report", id: 24, time: true },
          { title: "Groups Report", id: 23 },
          { title: "New Groups Report", id: 25, time:true},
          { title: "Loans Due Today Report", id: 3 },
          { title: "Group Loans Due Today Report", id: 40 },
          { title: "Loans Collected Report", id: 5, time: true },
          { title: "Loans in Arrears Report", id: 15, time: true },
          { title: "Arrears Collected Report", id: 15, time: true },
          { title: "Non-Performing Loans Report", id: 37, time: true },
          { title: "Disbursed Loans Today Report", id: 35 },//not 100% sure
          { title: "Active Loans Report", id: 1, time: true },
          { title: "Suspended Transactions Report", id: 14, time:true},
          { title: "Income Statement", id: 15, time:true},


        ].map(({ title, id, time }) => (
          <Card
            key={id}
            sx={{
              padding: "16px",
              marginTop: "16px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              transition: "transform 0.3s ease-in-out",
              "&:hover": { transform: "scale(1.05)" },
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
            {time && (
              <>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  sx={{ marginBottom: 2, width: "100%" }}
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  sx={{ marginBottom: 2, width: "100%" }}
                />
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                time
                  ? handleGenerateReportWithTime(id, title)
                  : handleGenerateReport(id, title)
              }
            >
              Generate Report
            </Button>
          </Card>
        ))}
        {isLoading && <CircularProgress />}
      </Box>
    </>
  );
};

export default ReportCards;
