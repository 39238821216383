import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";

import { CircularProgress } from "@mui/material";
import {
  customerservice,
  loanManagementUrl,
  setupManagementUrl,
} from "../../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
  fetchJson,
} from "../../../data/DynamicServiceNew/jsondynamicservice";
import { graphqlQuery } from "../../../data/Axios/DynamicService";

const RescheduleGroupLoan = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  // const customersData = [
  //   { id: '36991073', name: 'Lilian Kilani', email: 'fulusi@gmail.com', phone: '0798706353', branch: 'Kitengela', loanLimit: 3000, createdAt: 'Nov 29, 2021' },
  //   // Add more customer data as needed
  // ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState("");
  const [customers, setCustomers] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const resetForm = () => {
    setSearchTerm("");
    setFilteredCustomer(null);
    setAmount("");
    setLoanProductSelected("");
  };

  const handlesearch = async () => {
    setLoadingSearch(true); // Start loading

    try {
      const mutationdata = { groupcode: searchTerm };

      // Perform API call to search customer
      const customer = await fetchJson({
        objectName: "loanApplicationByGroup",
        inputObjectName: "loans",
        params: mutationdata,
        baseUrl: loanManagementUrl.uri,
      });

      const result =JSON.parse(customer.results);

      if (result === null || result.length === 0) {
        Swal.fire("Group Loan Not Found", "Group Loan does not exist", "error");
      }else{
      setFilteredCustomer(JSON.parse(customer.results) || null); // Update state with customer data
    }
    } catch (error) {
      console.error("Search error:", error);
      Swal.fire(
        "Search Failed",
        "An error occurred during the search. Please try again later.",
        "error"
      );
    } finally {
      setLoadingSearch(false); // End loading after search is completed
    }
  }; // Short debounce delay for a smoother typing experience

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAppraisalSubmit = async () => {
    setLoadingSubmit(true); // Start loading when the form is submitted

    try {
      const mutationData = {
        p_loanid: filteredCustomer[0].Id,
      };

      const response = await executeAnyMutation(
        "rescheduleGroupLoan",
        mutationData,
        "loanReschedule",
        false,
        loanManagementUrl.uri
      );
      const parsedResults = JSON.parse(response.results);
      if (parsedResults !== null) {
        if (parsedResults === 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Group Loan Rescheduled Successfully!",
          });
          // Reset form upon successful submission
          resetForm();
        } else {
          Swal.fire({
            icon: "error",
            title: "Unsuccessfull Group Loan Rescheduling!",
            text: parsedResults,
          });
        }
      } else if (response.errors) {
        throw new Error(
          response.errors[0].Error || "An internal server error occurred."
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
      resetForm();
    } finally {
      setLoadingSubmit(false); // End loading after the process
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Re-Schedule Group Loan</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField 
            label="Search Group Loan by Group Code"
            placeholder="Enter search criteria"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: loadingSearch && (
                <CircularProgress size={24} sx={{ marginRight: 2 }} />
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlesearch}
            disabled={loadingSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Loan Id:</strong>{" "}
                    {filteredCustomer[0].Id}
                  </Typography>
                  <Typography>
                    <strong>Amount To disburse:</strong> {filteredCustomer[0].AmountToDisburse}
                  </Typography>
                  <Typography>
                    <strong>Customer Phone:</strong>{" "}
                    {filteredCustomer[0].CustomerPhone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Application Date:</strong>{" "}
                    {filteredCustomer[0].ApplicationDate}
                  </Typography>
                  <Typography>
                    <strong>Company Comments:</strong> {filteredCustomer[0].CompanyComments}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer[0].DateCreated}
                  </Typography>
                  <Typography>
                    <strong>Expected Clear Date:</strong>{" "}
                    {filteredCustomer[0].ExpectedClearDate || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleAppraisalSubmit}
            disabled={loadingSubmit} // Disable button when loading
          >
            {loadingSubmit ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Reschedule Group Loan"
            )}
          </Button>
        </>
      )}
    </Box>
  );
};

export default RescheduleGroupLoan;