import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { companyManagementUrl } from "../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { fetchJsonDynamic } from "../../../data/DynamicServiceNew/jsondynamicservice";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const base_url = "https://localhost:7239/graphql/";

const actions = {
  entity: "CompanyFiles",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: false,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Activate/Deactivate",
    inputObjectName: "bulkActivateDeactivateFixedEntries",
    modelName: "fixedEntryIds",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  dataObject: {
    objectName: "companyFiles",
    parameters: "",
  },
  showStatus: true,
};

const CompanyFiles = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState([]); // To hold the uploaded files
  const[newFile, setNewFile ] = useState([]);
  const [isReplaceDialogOpen, setReplaceDialogOpen] = useState(false);
  const [fileToReplaceId, setFileToReplaceId] = useState(null); // Store ID of the file to replace

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const openReplaceDialog = (fileId) => {
    setFileToReplaceId(fileId); // Set the file ID to replace
    setReplaceDialogOpen(true); // Open the dialog
  };

  const closeReplaceDialog = () => {
    setReplaceDialogOpen(false); // Close the dialog
    setNewFile(null); // Clear the selected file for replacement
  };

  const handleReplaceFileSubmit = () => {
    if (fileToReplaceId && newFile) {
      handleReplace(fileToReplaceId); // Call the existing replace logic
      closeReplaceDialog(); // Close the dialog after submission
    } else {
      Swal.fire("Error", "Please select a file to replace.", "error");
    }
  };

  // Fetch uploaded files
 
    const fetchFiles = async () => {
      try {
        const response = await fetchJsonDynamic (
            "companyFiles",
            null,
            null,
            base_url
          );

        if (response?.results) {
            const files = response?.results;
            // Parse the stringified JSON array if it is a string
            const parsedFiles = JSON.parse(files);
            setFileList(parsedFiles || []);
            console.log ("Parsed Files", parsedFiles)
          }
      } catch (err) {
        console.error("Error fetching files", err);
      }
    };
    useEffect(() => {
    fetchFiles();
  }, [companyId, decodedToken?.token]);
  const fileInputRef = useRef(null); // Ref for upload file input
  const replaceFileInputRef = useRef(null); // Ref for replace file input
  
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    // Show loading Swal
    const swalLoading = Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload your file.",
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });
    // const fileToReplace = fileList.find((file) => file.id === fileId);

    setUploading(true); // Start uploading
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64FileData = reader.result.split(",")[1];  // Get base64 string from file data

      const byteArray = new Uint8Array(atob(base64FileData).split("").map((char) => char.charCodeAt(0)));
      const byteArrayAsNumbers = Array.from(byteArray);
      clearFileInput(fileInputRef);
      setFile(null); // Clear file state

      // Define GraphQL mutation with variables
      const mutation = `
        mutation CreateCompanyFiles($name: String!, $fileData: [Byte!]!, $fileType: String!, $uploadedBy: Int!) {
          createCompanyFiles(
            companyFiles: {
              name: $name,
              fileData: $fileData,
              fileType: $fileType,
              uploadedBy: $uploadedBy
            }
          ) {
            error
            results
          }
        }
      `;

      try {
        const response = await axios.post(base_url, {
          query: mutation,
          variables: {
            name: file.name,
            fileData: byteArrayAsNumbers,
            fileType: file.type,
            uploadedBy: userId,
          },
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${decodedToken?.token}`,
          },
        });

        // Close the loading Swal
        swalLoading.close();

        // Check if the response contains an error or success
        if (response.data.errors || response.data.data.createCompanyFiles.error) {
          setError("File upload failed. Please try again.");
          Swal.fire("Error", "File upload failed!", "error");
        } else {
          setUploadSuccess("File uploaded successfully!");
          Swal.fire("Success", "File uploaded successfully!", "success");
          fetchFiles();

          setFileList((prevFiles) => [...prevFiles, response.data.data.createCompanyFiles.results]);
        }
      } catch (err) {
        console.error("Error uploading file:", err);

        // Close the loading Swal
        swalLoading.close();

        setError("An error occurred while uploading the file.");
        Swal.fire("Error", "An error occurred while uploading the file.", "error");
      } finally {
        setUploading(false); // Stop uploading
      }
    };

    reader.readAsDataURL(file);  // This converts the file to base64
  };
 
// Clear file input function
const clearFileInput = (ref) => {
  if (ref.current) {
    ref.current.value = null; // Reset the input value
  }
};


  const handleDownloadFromData = (fileData, fileName, fileType) => {
    // Ensure the fileData is base64 encoded (split data URI if necessary)
    const base64Data = fileData.split(",")[1] || fileData; // Remove "data:*/*;base64," part if present
    
    // Convert base64 string to binary data
    const byteArray = new Uint8Array(atob(base64Data).split("").map((char) => char.charCodeAt(0)));
    
    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: fileType });  // fileType would be the MIME type of the file
    
    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);
    
    // Create a temporary anchor element to download the file
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName;  // Use the file name for the download
    link.click();  // Simulate click to trigger the download
  
    // Clean up the Blob URL after download
    URL.revokeObjectURL(blobUrl);
  };
  const handleDownload = () => {
    if (file) {
      // Assuming 'file' is the selected file stored in the state
      handleDownloadFromData(file.FileData, file.Name, file.FileType);
    } else {
      Swal.fire("Error", "No file selected for download.", "error");
    }
  };
  

  const handleReplace = async () => {
    if (!newFile || !fileToReplaceId) {
      Swal.fire("Error", "Please select a file to replace.", "error");
      return;
    }
  
    // Show loading Swal
    const swalLoading = Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload your file.",
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });
  
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64FileData = reader.result.split(",")[1]; // Extract base64 data
      const byteArray = new Uint8Array(atob(base64FileData).split("").map((char) => char.charCodeAt(0)));
      const byteArrayAsNumbers = Array.from(byteArray);
  
      const mutation = `
        mutation UpdateCompanyFiles($fileId: Int!, $fileData: [Byte!]!, $fileType: String!, $name: String!) {
          updateCompanyFiles(
            companyFiles: {
              id: $fileId,
              fileData: $fileData,
              fileType: $fileType,
              name: $name
            }
          ) {
            error
            results
          }
        }
      `;
  
      try {
        const response = await axios.post(base_url, {
          query: mutation,
          variables: {
            fileId: fileToReplaceId, // Use the correct file ID
            fileData: byteArrayAsNumbers,
            fileType: newFile.type,
            name: newFile.name,
          },
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${decodedToken?.token}`,
          },
        });
  
        swalLoading.close(); // Close loading Swal
  
        if (response.data.errors || response.data.data.updateCompanyFiles.error || response?.error) {
          Swal.fire("Error", "File update failed!", "error");
        } else {
          Swal.fire("Success", "File updated successfully!", "success");
          fetchFiles(); // Refresh the file list
  
          // Update local state
          setFileList((prevFiles) =>
            prevFiles.map((file) =>
              file.id === fileToReplaceId
                ? { ...file, ...response.data.data.updateCompanyFiles.results }
                : file
            )
          );
        }
      } catch (err) {
        console.error("Error updating file:", err);
        swalLoading.close();
        Swal.fire("Error", "An error occurred while updating the file.", "error");
      } finally {
        setUploading(false);
        closeReplaceDialog(); // Close the replace dialog
      }
    };
  
    reader.readAsDataURL(newFile); // Start reading the file
  };
  
  
  // Update the handleFileChange function to store the selected file for replacement
  const handleFileChangeForReplace = (event) => {
    const selectedFile = event.target.files[0];
    setNewFile(selectedFile);  // Store the new file for replacement
  };
  
  

  const columns = [
    { field: "Name", headerName: "File Name", width: 200 },
    { field: "FileType", headerName: "File Type", width: 150 },
    { field: "UploadedAt", headerName: "Uploaded At", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
           <Button onClick={() => {
                setFile(params.row); // Store the selected file in the state
                handleDownload(); // Call the download function
            }} variant="contained" color="primary">
                Download       
            </Button>
            <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: 10, paddingLeft: 20 }}
            startIcon={<CloudUploadIcon />}
            onClick={() => openReplaceDialog(params.row.id)} // Open popup for replacement
            >
            Replace
            </Button>

        </>
      ),
    },
  ];

  const rows = fileList.map((file, index) => ({
    id: file.Id,
    Name: file.Name,
    FileType: file.FileType,
    UploadedAt: file.UploadedAt,
    FileData: file.FileData,
  }));

  return (
    <Box m="20px">
      {/* File Upload Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          p: 2,
          mb: 4,
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
          Upload Company File
        </Typography>
        <TextField
          type="file"
          variant="outlined"
          inputRef={fileInputRef} // Attach ref to file input
          onChange={handleFileChange}
          inputProps={{
            accept: ".pdf,.doc,.docx,.xlsx,.csv", // Accepted file types
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          onClick={handleUpload}
          disabled={!file}
        >
          Upload
        </Button>
      </Box>

      {/* Data Grid Table */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid rows={rows} columns={columns} pageSize={5} />
          </div>
        </Grid>
      </Grid>
      <Dialog open={isReplaceDialogOpen} onClose={closeReplaceDialog}>
        <DialogTitle>Replace File</DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChangeForReplace} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeReplaceDialog} color="secondary">Cancel</Button>
          <Button onClick={handleReplaceFileSubmit} color="primary">Replace</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CompanyFiles;
