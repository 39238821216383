import React from "react";
import { Box } from "@mui/material";
import { loanManagementUrl } from "../../config";
import DynamicTableForJson from "../../data/DynamicTable/dynamicTableForJson";

const base_url = loanManagementUrl.uri;

const GroupLoanAppraisal = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: false,
      permission: "CreatePermission",
    },

    ApproveGroupReject: {
      key: "SETLOANLIMIT",
      button_name: "Approve/Reject loan",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4,5],
    },
    dataObject: {
      objectName: "allGroupApplications",
      parameters: "",
      ignoreFields: [
        "loanDisbursed",
        "loanBalance",
        "installments",
        "installmentAmount",
        "applicationDate",
        "isLoanApproved",
        "loanApprovalDate",
        "loanDisburseDate",
        "isLoanCleared",
        "interestAmount",
        "expectedClearDate",
        "loanClearedDate",
        "rolledOver",
        "productId",
        "isInArrears",
        "arrears",
        "arrearsOverdueDays",
        "principalArrears",
        "interestArrears",
        "isNonPerforming",
        "rolloverPenalty",
        "isWrittenOff",
        "loanDeclineId",
        "isLoanDeclined",
        "loanDeclinedReason",
        "writtenOffDate",
        "rolloverDate",
        "isFieldOfficerApproved",
        "fieldOfficerComment",
        "isBranchApproved",
        "branchComments",
        "isRegionApproved",
        "regionComments",
        "isCompanyApproved",
        "companyComments",
        "isMigrated",
        "remainingInstallments",
        "migratedNextInstallmentDate",
        "isRescheduled",
        "accountId",
        "serviceLoans",
        "isRebooked",
        "channelId",
        "branchId",
        "regionId",
        "companyId",
        "fieldOfficerApprovedBy",
        "fieldOfficerApprovedDate",
        "branchVerifiedBy",
        "branchVerifiedDate",
        "companyApprovedBy",
        "companyApprovedDate",
        "migratedBy",
        "migratedDate",
        "dateCreated",
        "createdBy",
        "updatedDate",
        "updatedBy",
        "verifiedBy",
        "verifiedDate",
        "isVerified",
        "regionApprovedBy",
        "regionApprovedDate",
        "name",
        "code",
        "loanLimit",
        "servicedLoans",
        "regFeePaid",
      ],
    },
    showStatus: true,
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Appraise Loans"
        subtitle="Loan Appraisal"
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default GroupLoanAppraisal;
