import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Dialog,
  TextField,
  Select,
  MenuItem,
  Divider,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import { customerservice, loanManagementUrl, setupManagementUrl } from "../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";
import DynamicTableForJson from "../../data/DynamicTable/dynamicTableForJson";
import Swal from "sweetalert2";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const LoanApprovalDialog = ({ open, onClose, Row }) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId) 
  const [activeTab, setActiveTab] = useState(0);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState([]);
  const [loanChargesDetails, setLoanChargesDetails] = useState([]);
  const [businessDetails, setBusinessDetails] = useState([]);
  const [gurantorDetails, setGuarantorDetails] = useState([]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const BusinessDetailsActions = {
    entity: "Customer",

    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: false,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: 
      [
        "UpdateDate", 
        "CreateDate",
        "LandMark",
        "HaveALocalAuthority",
        "VerifiedBy",
        "VerifiedDate",
      ],
      objectName: "customerBusinessDetailsById",
      parameters: { customerId: parseInt(Row.CustomerId) },
      inputObjectName: "customerBusinessDetails",
    },
  };
  const gurantorActions = {
    entity: "Customer",
    isSubright: true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: false,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: 
      [
        "UpdateDate", 
        "CreateDate",
        "VerifiedBy",
        "VerifiedDate",
        "HouseName",
        "FloorNumber",
        "RoomNumber",
        "Photo",
        "PhotoContentType",
        "IdFrontPhoto",
        "IdBackPhoto",
        "Town",
        "PermanentResidence",
        "Dob",
        "RelationshipToLoanee",
        "MaritalStatus",
      ],
      objectName: "getguarantorbycustomerid",
      parameters: { customerId: parseInt(Row.CustomerId) },
      inputObjectName: "guarantor",
    },
  };
  const LoanChargesActions = {
    entity: "Loans",
    isSubright: true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: false,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: ["UpdateDate", "CreateDate"],
      objectName: "allLoanChargesDetailsByLoanId",
      parameters: {loanId: parseInt(Row.Id)},
      inputObjectName: "parameters",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = { customerId: parseInt(Row.CustomerId) };
      const businessDetails = await fetchJsonDynamic(
        "customerBusinessDetailsById",
        "customerBusinessDetails",
        params,
        customerservice.uri
      );
      const allBusinessDetails = JSON.parse(businessDetails.results) || [];
      const guarantorDetails = await fetchJsonDynamic(
        "customerBusinessDetailsById",
        "customerBusinessDetails",
        params,
        customerservice.uri
      );
      const allGuarantorDetails = JSON.parse(guarantorDetails.results) || [];
      const productResponse = await fetchJsonDynamic(
        "allProductsByColumnValues",
        "parameters",
        {companyId: companyId},
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];
      setBusinessDetails(allBusinessDetails);
      setGuarantorDetails(allGuarantorDetails);
      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);

  const handleApprove = async () => {
    try {
        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: `Do you want to Approve this loan?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, approve it!`,
          cancelButtonText: "No, keep it",
          input: "text",
          inputPlaceholder: "Enter the reason for Approval",
          inputValidator: (value) => {
            if (!value) {
              return "You need to provide a reason for Approval!";
            }
          },
          didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          }// Ensure the backdrop is displayed properly
        });
    
        if (confirmation.isConfirmed) {
          const mutationData = {
            loan_Id: Row.Id,
            flag: 1,
            comments: confirmation.value,
          };
    
          const response = await executeAnyMutation(
            "approveRejectLoan",
            mutationData,
            "param",
            false,
            loanManagementUrl.uri
          );
    
          if (response.results) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: `Loan approved successfully.`,
              didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                if (swalContainer) {
                  swalContainer.style.zIndex = '2000';
                }
              }
            });
            
            onClose();
          }
          else if (response.error)
          {
            throw new Error
          }
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while processing your request. Please try again later.",
          didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          }
        });
      }
  };

  const handleReject = async () => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to reject this loan?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, reject it!`,
        cancelButtonText: "No, keep it",
        input: "text",
        inputPlaceholder: "Enter the reason for rejection",
        inputValidator: (value) => {
          if (!value) {
            return "You need to provide a reason for rejection!";
          }
        },
        didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          } // Ensure the backdrop is displayed properly
      });
  
      if (confirmation.isConfirmed) {
        const mutationData = {
          loan_Id: Row.Id,
          flag: 0,
          comments: confirmation.value,
        };
  
        const response = await executeAnyMutation(
          "approveRejectLoan",
          mutationData,
          "param",
          false,
          loanManagementUrl.uri
        );
  
        if (response.results) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Loan rejected successfully.`,
            didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                if (swalContainer) {
                  swalContainer.style.zIndex = '2000';
                }
              }
          });
          onClose();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
        didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          }
      });
    }
  };
  

  const productOptions = loanProduct
    ? loanProduct.map((loan) => ({
        key: loan.Id,
        value: loan.Id,
        label: loan.Name,
      }))
    : [];
    

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" disableEnforceFocus fullWidth>
      <Box
        sx={{
          bgcolor: "background.paper",
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
          maxWidth: "80vw",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Finance Manager Decision
          <Divider
            sx={{ borderBottomWidth: 2, border: "1px solid #003049" }}
            orientation="horizontal"
          />
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <StyledCard variant="outlined">
              <Typography variant="subtitle1" gutterBottom>
                <strong>Customer Details</strong>
              </Typography>
              <Typography>
                Customer Name:{" "}
                <strong>
                  {Row.FirstName} {Row.OtherNames}
                </strong>
              </Typography>
              <Typography>
                ID No: <strong>{Row.IdNoOrPassport}</strong>
              </Typography>
              <Typography>
                Phone: <strong>{Row.CustomerPhone}</strong>
              </Typography>
              <Typography>
                Branch: <strong>{Row.Name}</strong>
              </Typography>
              <Typography>
                RO:{" "}
                <strong>
                  {Row.FoFirstName} {Row.FoLastName}
                </strong>
              </Typography>
            </StyledCard>

            <StyledCard variant="outlined">
              <Typography
                variant="h6"
                sx={{ color: "#003049", fontWeight: "bold" }}
              >
                Loan Details
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {/* <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Loan Product</InputLabel>
                      <Select
                        value={loanProductSelected}
                        onChange={(e) => setLoanProductSelected(e.target.value)}
                        defaultValue=""
                      >
                        <MenuItem value="" disabled>
                          Select Loan Product
                        </MenuItem>
                        {productOptions?.map((loan) => (
                          <MenuItem key={loan.value} value={loan.value}>
                            {loan.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Amount to Disburse"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    <Typography>
                      Loan amount: <strong>{Row.LoanAmount}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Installments: <strong>{Row.Installments}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Installment amount:{" "}
                      <strong>{Row.InstallmentAmount}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Interest: <strong>{Row.InterestAmount}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Product: <strong>{Row.ProductName}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Charges Cleared: <strong>{loanChargesDetails.IsPaid}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Borrow date: <strong>{Row.ApplicationDate}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </StyledCard>

            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApprove}
                fullWidth
              >
                Approve
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={handleReject}
                fullWidth
              >
                Reject
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{ justifyContent: "space-between" }}
            >
              <Tab
                label="Business Details"
                sx={{
                  borderRadius: "0.75rem",
                  border: "1px solid #003049",
                  fontWeight: "bold",
                  marginRight: "10px",
                  mx: 0.5,
                }}
              />
              <Tab
                label="Guarantor Details"
                sx={{
                  borderRadius: "0.75rem",
                  border: "1px solid #003049",
                  fontWeight: "bold",
                  mx: 0.5,
                }}
              />
              <Tab
                label="Loan Charges Details"
                sx={{
                  borderRadius: "0.75rem",
                  border: "1px solid #003049",
                  fontWeight: "bold",
                  mx: 0.5,
                }}
              />
            </Tabs>

            {activeTab === 0 && (
              <StyledCard variant="outlined">
                <DynamicTableForJson
                  base_url={customerservice.uri}
                  actions={BusinessDetailsActions}
                />
              </StyledCard>
            )}

            {activeTab === 1 && (
              <StyledCard variant="outlined">
                <DynamicTableForJson
                  base_url={customerservice.uri}
                  actions={gurantorActions}
                />
              </StyledCard>
            )}
             {activeTab === 2 && (
              <StyledCard variant="outlined">
                <DynamicTableForJson
                  base_url={loanManagementUrl.uri}
                  actions={LoanChargesActions}
                />
              </StyledCard>
            )}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
