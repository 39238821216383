import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import { customerservice, reportsManagementUrl } from "../../../../../config";
import { generateReport } from "../../../../../data/DynamicTable/DynamicFunctions";
import { fetchJsonDynamic } from "../../../../../data/DynamicServiceNew/jsondynamicservice";
import { tokens } from "../../../../../theme";

const CustomerStatementDetails = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const CustomerId = localStorage.getItem("CustomerId");

  useEffect(() => {
    const variables = {
      id: parseInt(CustomerId),
    };
    async function fetchData() {
      const response = await fetchJsonDynamic(
        "customersByColumnValues",
        "customer",
        variables,
        customerservice.uri
      );
      setData(JSON.parse(response.results));
    }
    fetchData();
  }, []);

  const handleGenerateReport = async () => {
    const ReportTypeId = 42;
    const ReportType = "Customer Income Statement";

    try {
      setIsLoading(true);
      const mutationData = {
        ReportTypeId,
        ReportType,
        CustomerId,
        StartDate: startDate,
        EndDate: endDate,
      };

      const newTab = window.open("", "_blank");
      newTab.document.write(`<p>Loading...</p>`);

      const response = await generateReport(
        mutationData,
        reportsManagementUrl.generate_report_uri,
        setIsLoading
      );

      if (response.ok) {
        const htmlContent = await response.text();
        newTab.document.open();
        newTab.document.write(htmlContent);
        newTab.document.close();
      } else {
        newTab.close();
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to generate the report. Please try again later.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while generating the report.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 600, margin: "auto" }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Customer Details
      </Typography>

      <TableContainer
        component={Paper}
        sx={{
          marginBottom: 4,
          background: `${colors.primary[200]} !important`,
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Adds a shadow
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Field</strong>
              </TableCell>
              <TableCell>
                <strong>Value</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Active Loan</TableCell>
              <TableCell>{data?.[0]?.ActiveLoan || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>{data?.[0]?.FirstName || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell>{data?.[0]?.OtherNames || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mobile Number</TableCell>
              <TableCell>{data?.MobileNumber || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date Created</TableCell>
              <TableCell>
                {data?.[0]?.DateCreated
                  ? new Date(data?.[0]?.DateCreated).toLocaleDateString()
                  : "N/A"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>OLB</TableCell>
              <TableCell>KSH{data?.[0]?.LoanBalance || 0.0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Arrears</TableCell>
              <TableCell>KSH{data?.[0]?.Arrears || 0.0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Days Overdue</TableCell>
              <TableCell>{data?.[0]?.DaysOverdue || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BBF Acount Balance</TableCell>
              <TableCell>KSH{data?.[0]?.CustomerBalance || 0.0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Generate Customer Statement
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateReport}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? <CircularProgress size={24} /> : "Generate Report"}
        </Button>
      </Box>
    </Box>
  );
};

export default CustomerStatementDetails;
