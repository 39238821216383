import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../../../data/DynamicTable/dynamicTableForJson";
import { customerservice } from "../../../../../config";
import GuarantorForm from "./form";
import { useNavigate } from "react-router-dom";



const base_url = customerservice.uri;

const GuarantorsTable = () => {
  const customerId= localStorage.getItem("CustomerId")
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const navigate = useNavigate();

  const actions = {
    entity: "Customer",
    isSubright:true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: 
      [
        "UpdateDate", 
        "CreateDate",
        "VerifiedBy",
        "VerifiedDate",
        "HouseName",
        "FloorNumber",
        "RoomNumber",
        "Photo",
        "PhotoContentType",
        "IdFrontPhoto",
        "IdBackPhoto",
        "Town",
        "PermanentResidence",
        "Dob",
        "RelationshipToLoanee",
        "MaritalStatus",
      ],
      objectName: "guarantorById",
      parameters:{customerId:parseInt(customerId)},
      inputObjectName:"guarantor"
    },

  };

  return (
    <Box m="20px">
       <Box >
       <Button onClick={ () => navigate(-1)} variant="contained">
            Back to Customers
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="Guarantors"
        subtitle="Manage Guarantors"
        FormComponent={GuarantorForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default GuarantorsTable;
