import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { executeAnyMutation } from "../../../data/DynamicServiceNew/jsondynamicservice";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  generateMutation,
  graphqlMutation,
} from "../../../data/Axios/DynamicService";
import { reportsManagementUrl, setupManagementUrl } from "../../../config";

const base_url = reportsManagementUrl.uri;

const MajorClassificationForm = (props) => {
  const initialValues = {
    majorClassification: props.data ? props.data.MajorClassification : "",
  };

  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const validationSchema = yup.object().shape({
    majorClassification: yup
      .string()
      .required("Major classification is required"),
  });

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { majorClassification } = values;
      const createdBy = userId
      const variables = props.isEditing
      ? {
          id: props.data.Id,
          majorClassification,
        }
      : {
         majorClassification,
         createdBy,
        };

    const response = await executeAnyMutation(
      props.isEditing ? "updateReportsMajorClassification" : "createReportsMajorClassification",
      variables,
      "result",
      false,
      base_url
    );

    if (response.results !== null) {
      props.onClose();
      props.onAction();
      await Swal.fire(
        "Success!",
        `Major Classification has been ${props.isEditing ? "updated" : "created"} successfully`,
        "success"
      );
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
  setSubmitting(false);
};

  return (
    <Box m="10px">
      <Header
        title={
          props.isEditing
            ? "Edit Major Classification"
            : "Create Major Classification"
        }
        subtitle={
          props.isEditing
            ? "Edit an Existing Major Classification"
            : "Create a New Major Classification"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Major classification *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.majorClassification}
                name="majorClassification"
                error={
                  touched.majorClassification && !!errors.majorClassification
                }
                helperText={
                  touched.majorClassification && errors.majorClassification
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default MajorClassificationForm;
