import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { customerservice } from "../../../config";
import CustomerForm from "./form";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { CloudUpload } from "@mui/icons-material";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
const base_url = customerservice.uri;

const ignoreFields = [
  "CompanyName",
  "Id",
  "TitleId",
  "Password",
  "GenderId",
  "DateOfBirth",
  "MaritalStatusId",
  "PostalAddress",
  "PhysicalAddress",
  "ResidenceTypeId",
  "YearsLivedAtResidence",
  "CountyId",
  "WardId",
  "BranchId",
  "RegionId",
  "FieldOfficerId",
  "AccountLocked",
  "LoginAttempts",
  "FirstTimeLogin",
  "LoanLimit",
  "AverageCashSales",
  "DateCreated",
  "DeviceName",
  "DeviceId",
  "UniqueId",
  "LastLoginDateTime",
  "Pin",
  "Otp",
  "PinLastUpdated",
  "DateReactivated",
  "PostalCode",
  "ApprovalComments",
  "AverageSalesApproved",
  "AverageSalesApprovedBy",
  "AverageSalesApprovedDate",
  "AverageSalesReasonForRejection",
  "IdFrontPhoto",
  "IdBackPhoto",
  "CustomerVerified",
  "SelfiePhoto",
  "CustomerDeactivatedBy",
  "DeactivatedDate",
  "IsLoanScheduleGenerated",
  "DateOfLastLimitChange",
  "CreditInfoRecommendation",
  "ApprovedBy",
  "IsIPRSChecked",
  "RegFeePaid",
  "UpdatedBy",
  "MigratedBy",
  "MigrationDate",
  "ServicedLoans",
  "InActiveOLB",
  "Latitude",
  "Longitude",
  "MappedBy",
  "GroupOLB"
];
const columns = [
  { field: "FirstName", headerName: "First Name", flex: 1 },
  { field: "OtherNames", headerName: "Other Names"},
  { field: "MobileNumber", headerName: "Customer Phone" },
  { field: "EmailAddress", headerName: "Email Address",flex: 1 },
  { field: "IdNoOrPassport", headerName: "Id No Or Passport" },
  { 
    field: "IsApproved", 
    headerName: "Loan Approved", 
    flex: 1,
    renderCell: (params) => (
      <Typography 
        style={{
          color: params.value ? "green" : "red",
          
        }}
      >
        {params.value ? "True" : "False"}
      </Typography>
    ),
  },
];


const actions = {
  entity: "Customer",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
    modelName: "customerIds", // Specify the model name here
    inputObjectName: "bulkActivateDeactivateCustomers",
  },
  ViewCustomerDetails: {
    key: "VIEWDETAILS",
    button_name: "View Details",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4, 5],
  },
  ViewCustomer360: {
    key: "ViewAllCustomerDetails",
    button_name: "View Customer 360",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4, 5],
  },
  SetLoanLimit: {
    key: "SETLOANLIMIT",
    button_name: "Set Loan Limit",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  dataObject: {
    ignoreFields,
    objectName: "allCustomers",
    parameters: "",
  },
  showMore: {
    objectname: "customersByColumnValues",
    inputsubobjectname: "customer",
    parameters: "mobileNumber",
    search: true,
  },
};
function CustomersJson() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleOpenModal = () => {
    setDialogOpen(true);
  };

  return (
    <Box m="2px">
      <Box
        m="2px"
        sx={{
          justifyContent: "end",
          alignItems: "center",
          display: "flex",
        }}
      >
        {" "}
        <Typography style={{ color: colors.greenAccent[400], margin: "0 8px" }}>
          Import Customer(s)
        </Typography>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUpload />}
          sx={{
            backgroundColor: colors.greenAccent[500],
            borderRadius: "4px",
            m: "10px",
          }}
          onClick={handleOpenModal}
        >
          <Typography style={{ color: colors.primary[400], margin: "0 8px" }}>
            Import
          </Typography>
        </Button>
      </Box>
      <DynamicTableForJson
        title="Customers"
        subtitle="View all customers, create edit or view details"    
        FormComponent={CustomerForm}
        columns={columns}
        base_url={base_url}
        actions={actions}
       
      />
    </Box>
  );
}

export default CustomersJson;