import React, { useState } from 'react';

const TermsAndConditions = () => {
  const [terms, setTerms] = useState(
    "These are the current terms and conditions. You can edit and update them as needed."
  );
  const [isEditing, setIsEditing] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    setTerms(e.target.value);
  };

  // Toggle edit mode
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // Save terms (dummy action for now)
  const saveTerms = () => {
    console.log('Terms saved:', terms); // Log the updated terms
    setIsEditing(false);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center' }}>Manage Terms and Conditions</h1>
      <div style={{ margin: '20px 0', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
        {isEditing ? (
          <textarea
            value={terms}
            onChange={handleInputChange}
            rows="10"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              border: '1px solid #ddd',
              borderRadius: '5px',
            }}
          ></textarea>
        ) : (
          <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px', lineHeight: '1.5' }}>
            {terms}
          </div>
        )}
      </div>
      <div style={{ textAlign: 'center' }}>
        {isEditing ? (
          <>
            <button
              onClick={saveTerms}
              style={{
                padding: '10px 20px',
                margin: '5px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Save
            </button>
            <button
              onClick={toggleEdit}
              style={{
                padding: '10px 20px',
                margin: '5px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={toggleEdit}
            style={{
              padding: '10px 20px',
              backgroundColor: '#2196F3',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Edit Terms
          </button>
        )}
      </div>
    </div>
  );
};

export default TermsAndConditions;
