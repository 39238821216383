import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../../data/Axios/DynamicForm";
import { executeAnyMutation } from "../../../../data/DynamicServiceNew/jsondynamicservice";
import { AccountsandFinanceurl, loanManagementUrl } from "../../../../config";
import { suspendedTransactionFields } from "../../../../data/DynamicForm/FormFields/SuspendedTransactionFormField";

const base_url = AccountsandFinanceurl.uri;

const SuspendedTransactionForm = (props) => {
  const initialValues = {
    id: props.data ? props.data.Id : "",
    billRefNumber: props.data ? props.data.BillRefNumber : "",
    businessShortCode: props.data ? props.data.BusinessShortCode : "",
    firstName: props.data ? props.data.FirstName : "",
    lastName: props.data ? props.data.LastName : "",
    invoiceNumber: props.data ? props.data.InvoiceNumber : "",
    middleName: props.data ? props.data.MiddleName : "",
    msisdn: props.data ? props.data.MSISDN : "",
    orgAccountBalance: props.data ? props.data.OrgAccountBalance : 0,
    thirdPartyTransID: props.data ? props.data.ThirdPartyTransID : "",
    transactionType: props.data ? props.data.TransactionType : "",
    transAmount: props.data ? props.data.TransAmount : 0,
    transID: props.data ? props.data.TransID : "",
    transTime: props.data ? props.data.TransTime : "",
    isReconcilled: props.data ? props.data.IsReconcilled : 0,
    companyId: props.data ? props.data.CompanyId : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData = {
        ...values,
      };

      const mutaionDataEditing = {
        loanId: props.data.Id,
        p_BillRefNumber: values.billRefNumber,
      };

      const response = await executeAnyMutation(
        props.isEditing
          ? "mpesaManualReconciliation"
          : "createSuspendedTransaction",
        props.isEditing ? mutaionDataEditing : mutationData,
        props.isEditing ? "recon" : "suspendedC2B",
        false,
        props.isEditing ? loanManagementUrl.uri : base_url
      );

      if (response.results) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Suspended Transaction ${
            props.isEditing ? "Transaction Reconciled" : "created"
          } successfully.`,
        });
      } else if (response.error) {
        throw new Error(response.error);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={suspendedTransactionFields}
      onSubmit={handleSubmit}
      title={
        props.isEditing
          ? "Update and Reconcile"
          : "Create Suspended Transaction"
      }
      subtitle={
        props.isEditing
          ? "Update and Reconcile Suspended Transaction"
          : "Create a new Suspended Transaction"
      }
    />
  );
};

export default SuspendedTransactionForm;