import React, { useState} from "react";
import { Box } from "@mui/material";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import FixedExpenseForm from "./FixedExpenseform";
import {companyManagementUrl} from "../../../config";


const base_url = companyManagementUrl.uri;
const ignoreFields = [ "interval"]

  const actions = {
    entity: "FixedExpenses",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Activate/Deactivate",
      inputObjectName: "bulkActivateDeactivateFixedEntries",
      modelName:"fixedEntryIds",
      Show_Button: true,
      permission: "ActivatePermission",
    },
    AssignTask: {
      key: "ASSIGN_TASK",
      button_name: "Assign Task",
      Show_Button: false,
      permission: "hasNoPermission",
      roleGroupId: [1, 2],
    },
    dataObject: {
      ignoreFields,
      objectName: "allFixedEntriesView",
      parameters: "",
    },
    showStatus: true,
  };
  const FixedExpenses = () => {
    const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
    const userId = parseInt(decodedToken.Id);
    const companyId = parseInt(decodedToken.CompanyId);
    
  return (
    <Box m="20px">
    <>
      <DynamicTableForJson
        title="Fixed Expenses"
        subtitle="Manage Fixed Expenses"
        FormComponent={FixedExpenseForm}
        base_url={base_url}
        actions={actions}
      />
      </>
    </Box>
  );
};

export default FixedExpenses;
