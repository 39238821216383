import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../../../data/DynamicTable/dynamicTableForJson";
import { customerservice } from "../../../../../config";
import GroupRoleForm from "./form";
import { useNavigate } from "react-router-dom";



const base_url = customerservice.uri;

const GroupRolesTable = () => {

const groupId= localStorage.getItem("GroupId")
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const navigate = useNavigate();

  const actions = {
    entity: "Groups",
    isSubright:true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: ["UpdateDate", "CreateDate",],
      objectName: "groupRolesByColumnValues",
      parameters:{groupId:parseInt(groupId)},
      inputObjectName:"groupRole"
    },

  };

  return (
    <Box m="20px">
      <Box >
       <Button onClick={ () => navigate(-1)} variant="contained">
            Back to Groups
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="Group Roles"
        subtitle="Manage Group Roles"
       FormComponent={GroupRoleForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default GroupRolesTable;
