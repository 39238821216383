import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";

import { CircularProgress } from "@mui/material";
import {
  customerservice,
  loanManagementUrl,
  setupManagementUrl,
} from "../../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
  fetchJson,
} from "../../../data/DynamicServiceNew/jsondynamicservice";
import { graphqlQuery } from "../../../data/Axios/DynamicService";
import { tokens } from "../../../theme";
import { useTheme } from "@emotion/react";

const RebookGroupLoan = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);

  // const customersData = [
  //   { id: '36991073', name: 'Lilian Kilani', email: 'fulusi@gmail.com', phone: '0798706353', branch: 'Kitengela', loanLimit: 3000, createdAt: 'Nov 29, 2021' },
  //   // Add more customer data as needed
  // ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState("");
  const [customers, setCustomers] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const resetForm = () => {
    setSearchTerm("");
    setFilteredCustomer(null);
    setAmount("");
    setLoanProductSelected("");
  };

  const handlesearch = async () => {
    setLoadingSearch(true); // Start loading

    try {
      const mutationdata = { searchParameter: searchTerm };

      // Perform API call to search customer
      const customer = await fetchJson({
        objectName: "searchGroupForLoanRebooking",

        inputObjectName: "searchGroupforLoanRebooking",
        params: mutationdata,
        baseUrl: customerservice.uri,
      });

      if (JSON.parse(customer.results) === null) {
        Swal.fire("Customer Not Found", "Customer does not exist", "warning");
      }
      setFilteredCustomer(JSON.parse(customer.results) || null); // Update state with customer data
    } catch (error) {
      console.error("Search error:", error);
      Swal.fire(
        "Search Failed",
        "An error occurred during the search. Please try again later.",
        "error"
      );
    } finally {
      setLoadingSearch(false); // End loading after search is completed
    }
  }; // Short debounce delay for a smoother typing experience

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = { companyId: companyId };
      const productResponse = await fetchJsonDynamic(
        "allProductsByColumnValues",
        "parameters",
        params,
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];
      // Filter regions based on selected company ID

      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);
  const handleAppraisalSubmit = async () => {
    setLoadingSubmit(true); // Start loading when the form is submitted

    try {
      const mutationData = {
        p_groupId: filteredCustomer.group_id,
        p_principle: parseInt(amount),
        p_productid: loanProductSelected,
      };

      const response = await executeAnyMutation(
        "rebookGroupLoan",
        mutationData,
        "loanRebooking",
        false,
        loanManagementUrl.uri
      );
      const parsedResults = JSON.parse(response.results);
      if (parsedResults.LoanId !== null) {
        if (parsedResults > 0) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Loan Re-booked Successfully!",
          });
          // Reset form upon successful submission
          await handlesearch();
        } else if (parsedResults === -53) {
          Swal.fire({
            icon: "error",
            title: "Sorry!",
            text: "Deposit for this loan is required!",
          });
          // Reset form upon successful submission
        } else if (parsedResults === -63) {
          Swal.fire({
            icon: "error",
            title: "Sorry!",
            text: "The chosen Product has no charges!",
          });
          // Reset form upon successful submission
        } else if (parsedResults === -62) {
          Swal.fire({
            icon: "error",
            title: "Sorry!",
            text: "The chosen Group has no Members!",
          });
          // Reset form upon successful submission
        } else if (parsedResults === -50) {
          Swal.fire({
            icon: "error",
            title: "Sorry!",
            text: "Principle Applied is not within Product Loan Range!",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Unsuccessfull Loan Rebooking!",
            text: parsedResults,
          });
        }
      } else if (response.errors) {
        throw new Error(
          response.errors[0].Error || "An internal server error occurred."
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
      resetForm();
    } finally {
      setLoadingSubmit(false); // End loading after the process
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Re-Book Loan</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            label="Search Group Loan by group phone number | Group Code"
            placeholder="Enter search criteria"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: loadingSearch && (
                <CircularProgress size={24} sx={{ marginRight: 2 }} />
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlesearch}
            disabled={loadingSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent sx={{ bgcolor: `${colors.primary[200]}` }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Customer Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Group Name:</strong>{" "}
                    {filteredCustomer.name
                      }
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {filteredCustomer.email_address}
                  </Typography>
                  <Typography>
                    <strong>ID Number:</strong>{" "}
                    {"N/A"
                      }
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Mobile Number:</strong>{" "}
                    {filteredCustomer.mobile_number}
                  </Typography>
                  <Typography>
                    <strong>Branch:</strong> {filteredCustomer.branch_name}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer.date_created}
                  </Typography>
                  <Typography>
                    <strong>Current Loan Limit:</strong>{" "}
                    {filteredCustomer.loan_limit || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent sx={{ bgcolor: `${colors.primary[200]}` }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Old Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Loan Product:</strong>{" "}
                    {filteredCustomer.product_name || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Amount To Disburse:</strong>{" "}
                    {filteredCustomer.amount_to_disburse}
                  </Typography>
                  <Typography>
                    <strong>Loan Amount:</strong> {filteredCustomer.loan_amount}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Loan Balance:</strong>{" "}
                    {filteredCustomer.loan_balance}
                  </Typography>
                  <Typography>
                    <strong>Borrow Date:</strong> {filteredCustomer.borrow_date}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent sx={{ bgcolor: `${colors.primary[200]}` }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Loan Product</InputLabel>
                    <Select
                      value={loanProductSelected}
                      onChange={(e) => setLoanProductSelected(e.target.value)}
                      defaultValue=""
                    >
                      <MenuItem value="" disabled>
                        Select Loan Product
                      </MenuItem>
                      {loanProduct.map((company) => (
                        <MenuItem key={company.Id} value={company.Id}>
                          {company.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Amount to Disburse"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleAppraisalSubmit}
            disabled={loadingSubmit} // Disable button when loading
          >
            {loadingSubmit ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit For Appraisal"
            )}
          </Button>
        </>
      )}
    </Box>
  );
};

export default RebookGroupLoan;
