export const accountCategoryFields = [
  {
    name: "categoryName",
    label: "Category Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "currency",
    label: "Currency *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "rate",
    label: "Rate *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  //   {
  //     name: "parentAccount",
  //     label: "Parent Account",
  //     type: "text",
  //     gridColumn: "span 2",
  //   },
  //   {
  //     name: "oldParent",
  //     label: "Old Parent",
  //     type: "text",
  //     gridColumn: "span 2",
  //   }
];
export const accountSubcategoryFields = [
  {
    name: "accountName",
    label: "Account Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "accountNumber",
    label: "Account Number *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "accountType",
    label: "Account Type *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },

  {
    name: "currency",
    label: "Currency *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },

  {
    name: "rate",
    label: "Rate *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
];
export const accountFields = [
  {
    name: "accountName",
    label: "Account Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "accountNumber",
    label: "Account Number *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "accountType",
    label: "Account Type *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },

  {
    name: "currency",
    label: "Currency *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const SuspendedTransactionsFields = [
  {
    name: "firstName",
    label: "First Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "invoiceNumber",
    label: "Invoice Number *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "transAmount",
    label: "Transaction Amount *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
];
export const paymentTypeFields = [
  {
    name: "paymentType",
    label: "Payment Type *",
    type: "text",
    gridColumn: "span 4",
    required: true,
  },
];
export const bankFields = [
  {
    name: "bankName",
    label: "Bank Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "bankSwiftCode",
    label: "Bank SWIFT Code *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "finCode",
    label: "FIN Code *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "mobile",
    label: "Mobile *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "email",
    label: "Email *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];

export const bankBranchFields = [
  {
    name: "branchCode",
    label: "Branch Code *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "branchName",
    label: "Branch Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "branchSwiftCode",
    label: "Branch SWIFT Code *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const bankAccountFields = [
  {
    name: "accountName",
    label: "Account Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "accountNumber",
    label: "Account Number *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "accountDescription",
    label: "Account Description *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];

export const fiscalYearFields = [
  {
    name: "fiscalYearName",
    label: "Fiscal Year Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "startDate",
    label: "Start Date *",
    type: "date",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "endDate",
    label: "End Date *",
    type: "date",
    gridColumn: "span 2",
    required: true,
  },
];
export const taxAuthorityFields = [
  {
    name: "name",
    label: "Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "jurisdiction",
    label: "Jurisdiction *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const taxRateFields = [
  {
    name: "name",
    label: "Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "rate",
    label: "Rate *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
];
export const subRightsFields = [
  {
    name: "title",
    label: "Title *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const chargeLineFields = [
  {
    name: "description",
    label: "Description",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "price",
    label: "Price *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
];
export const productFields = [
  {
    name: "productName",
    label: "Product Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },

  {
    name: "unitPrice",
    label: "Unit Price *",
    type: "number",
    gridColumn: "span 1",
    required: true,
  },

  {
    name: "isInventory",
    label: "Is this a Butchery Item?",
    type: "checkbox",
    gridColumn: "span 1",
    required: false,
    parent:"main"
  },
];
export const FixedExpenseFields = [
  {
    name: "entry",
    label: "Entry *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "amount",
    label: "Amount ($)",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "description",
    label: "Description *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
