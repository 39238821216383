import { AlignEndHorizontal } from "lucide-react";
import React from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const CustomTable = (data) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const transformedData = data.data.map((item) => {
    const {
      Id,
      LoanId,
      IsCleared,
      PrincipalAmount,
      InterestAmount,
      Penaltyamount,
      IsPrinciplePaid,
      IsInterestPaid,
      IsPenaltyPaid,
      ExpectedDueDate,
      InterestPaidDate,
      PrincipalPaidDate,
      PenaltyPaidDate,
      IsInArrears,
    } = item;

    return {
      loanId: Id.toString(),
      status: IsCleared === 1 ? "Cleared" : "Unpaid",
      expectedduedate: new Date(ExpectedDueDate).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
      details: [
        {
          type: "Principal",
          amount: PrincipalAmount ? PrincipalAmount : 0,
          paid: IsPrinciplePaid ? PrincipalAmount : 0,
          status: IsPrinciplePaid ? "Paid" : "Unpaid",
          duedate: PrincipalPaidDate
            ? new Date(PrincipalPaidDate).toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })
            : "N/A",
          inarrears: IsInArrears ? "True" : "False",
        },
        {
          type: "Interest",
          amount: InterestAmount ? InterestAmount : 0.0,
          paid: IsInterestPaid ? InterestAmount : 0,
          status: IsInterestPaid ? "Paid" : "Unpaid",
          duedate: InterestPaidDate
            ? new Date(InterestPaidDate).toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })
            : "N/A",
          inarrears: IsInArrears ? "True" : "False",
        },
        {
          type: "Penalty",
          amount: Penaltyamount ? Penaltyamount : 0.0,
          paid: IsPenaltyPaid ? Penaltyamount : 0,
          status:
            Penaltyamount === 0 ? "N/A" : IsPenaltyPaid ? "Paid" : "Unpaid",
          duedate: PenaltyPaidDate
            ? new Date(PenaltyPaidDate).toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })
            : "N/A",
          inarrears: IsInArrears ? "True" : "False",
        },
      ],
    };
  });

  return (
    <div style={{ margin: "20px" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "left",
          fontFamily: "Arial, sans-serif",
          backgroundColor: `${colors.primary} !important`,
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              ID
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              Due Date
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            ></th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              to Pay
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              Paid
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              unPaid
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              OverPayment
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              Payment Date
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              In Arrears
            </th>
            <th
              style={{
                ...headerStyle,
                backgroundColor: `${colors.primary[200]} !important`,
              }}
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: `${colors.primary[100]} !important` }}>
          {transformedData.map((loan, index) => (
            <React.Fragment key={index}>
              {loan.details.map((detail, i) => (
                <tr key={i} style={rowStyle}>
                  {/* Loan ID - Only for the first row */}
                  {i === 0 && (
                    <td
                      rowSpan={loan.details.length}
                      style={{
                        ...mergedCellStyle,
                        backgroundColor: `${colors.primary[40]} !important`,
                      }}
                    >
                      {loan.loanId}
                    </td>
                  )}
                  {i === 0 && (
                    <td
                      rowSpan={loan.details.length}
                      style={{
                        ...mergedCellStyle,
                        backgroundColor: `${colors.primary[400]} !important`,
                      }}
                    >
                      {loan.expectedduedate}
                    </td>
                  )}
                  {/* Details */}
                  <td style={cellStyle}>{detail.type}</td>
                  <td style={{ ...cellStyle, textAlign: "right" }}>
                    {detail.amount}
                  </td>
                  <td style={{ ...cellStyle, textAlign: "right" }}>
                    {detail.paid}
                  </td>
                  <td style={{ ...cellStyle, textAlign: "right" }}>{`${
                    detail.amount - detail.paid
                  }`}</td>
                  <td style={{ ...cellStyle, textAlign: "right" }}>{`${
                    parseInt(detail.paid - detail.amount) >= 0
                      ? detail.paid - detail.amount
                      : 0
                  }`}</td>
                  <td style={cellStyle}>{detail.duedate}</td>
                  {i === 0 && (
                    <td
                      rowSpan={loan.details.length}
                      style={{
                        ...mergedCellStyle,
                        backgroundColor: `${colors.primary[400]} !important`,
                      }}
                    >
                      {detail.inarrears}
                    </td>
                  )}
                  <td
                    style={{
                      ...cellStyle,
                      color: detail.status === "Paid" ? "green" : "red",
                    }}
                  >
                    {detail.status}
                  </td>
                  {/* Status - Only for the first row */}
                  {/* {i === 0 && (
                    <td rowSpan={loan.details.length} style={{...mergedCellStyle, backgroundColor:`${colors.primary[400]} !important`}}>
                      {loan.status}
                    </td>
                  )} */}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const headerStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  fontWeight: "bold",
};

const rowStyle = {
  border: "1px solid #ddd",
  padding: "8px",
};

const cellStyle = {
  border: "1px solid #ddd",
  padding: "8px",
};

const mergedCellStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "center",
  verticalAlign: "middle",
};

export default CustomTable;
