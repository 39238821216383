import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DynamicForm from "../../../../../data/Axios/DynamicForm";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice } from "../../../../../config";

const base_url = customerservice.uri;

const GroupMembersForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const groupId = parseInt(localStorage.getItem("GroupId"));
  const userId = parseInt(decodedToken.Id);
  const [inputedNumber, setInputedNumber] = useState("");
  const [customersData, setCustomersData] = useState([]);
  const [groupRolesData, setGroupRolesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false); // For search loading state

  useEffect(() => {
    async function fetchAllData() {
      try {
        const parameters = { groupId };

        // Fetch customers already in the group
        const customerByGroup = await fetchJsonDynamic(
          "customerGroupViewByColumnValues",
          "customerGroupView",
          parameters,
          base_url
        );
        const existingGroupCustomers = JSON.parse(customerByGroup?.results) || [];
        const existingCustomerIds = existingGroupCustomers.map((customer) => customer.CustomerId);

        // Fetch all customers
        const customersResponse = await fetchJsonDynamic("allCustomers", null, null, base_url);
        if (customersResponse?.results) {
          const allCustomers = JSON.parse(customersResponse?.results) || [];

          // Filter out customers already in the group
          const filteredCustomers = allCustomers.filter(
            (customer) => !existingCustomerIds.includes(customer.Id)
          );
          setCustomersData(filteredCustomers);
        }

        // Fetch group roles
        const groupRolesResponse = await fetchJsonDynamic(
          "groupRolesByColumnValues",
          "groupRole",
          parameters,
          base_url
        );
        if (groupRolesResponse?.results) {
          const roles = JSON.parse(groupRolesResponse?.results) || [];
          setGroupRolesData(roles);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, [groupId]);

  const customersOptions = customersData.map((customer) => ({
    value: customer.Id,
    label: `${customer.FirstName} ${customer.OtherNames} - ${customer.MobileNumber}`,
  }));

  const roleOptions = groupRolesData.map((role) => ({
    value: role.Id,
    label: role.RoleName,
  }));

  const fields = [
    {
      name: "customerIds",
      label: "Customers",
      type: "multiselect",
      options: customersOptions || '',
      queryConfig: {
        queryName: "customerByMobileNumber", // Name of the query to execute
        baseUrl: base_url, // Unique base URL for this query
        variables: { mobileNumber: inputedNumber  }, // Base variables for the query
        transformResult: (results) =>
          results?.customers?.map((customer) => ({
            value: customer.id,
            label: `${customer.name} (${customer.mobileNumber})`,
          })),
      },
      gridColumn: "span 2", // Make it span across two grid columns
      fullWidth: true,
    },
    {
      name: "roleId",
      label: "Roles",
      type: "select",
      options: roleOptions,
      required: true,
      gridColumn: "span 2",
    },
  ];

  const initialValues = {
    groupId: "",
    roleId: "",
    customerIds: "",
    createdBy: userId,
    createdDate: new Date().toISOString(),
    updatedBy: userId,
    updatedDate: new Date().toISOString(),
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { roleId, customerIds } = values;

      // Construct a single payload
      const mutationData = {
        groupId: parseInt(groupId),
        roleId: parseInt(roleId),
        customerIds,
      };

      // Send a single mutation request with the payload
      const response = await executeAnyMutation(
        "createCustomerGroup",
        mutationData,
        "inputModel",
        false,
        base_url
      );

      if (response && response.results) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "CustomerGroup records created successfully.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  const handleFieldChangeInternal = (setFieldValue, field, value, values) => {
    setFieldValue(field.name, value);

    if (field.name === "customerIds") {
      // Normalize the mobile number input
      const normalizedSearchTerm =
        value.startsWith("0")
          ? value.replace(/^0/, "+254")
          : value.startsWith("+254")
          ? value
          : value;

      setInputedNumber(normalizedSearchTerm); // Dynamically update inputedNumber state
    }
  };

  const handleSearchDebounce = (e) => {
    setLoadingSearch(true);
    const searchTerm = e.target.value;

    // Normalize search term
    const normalizedSearchTerm =
      searchTerm.startsWith("0")
        ? searchTerm.replace(/^0/, "+254") // Convert local number (starting with 0) to international format (+254)
        : searchTerm.startsWith("+254")
        ? searchTerm
        : searchTerm;

    setInputedNumber(normalizedSearchTerm); // Set the normalized number

    // Perform the search once the number is valid (10 digits or in international format)
    if (/^\d{10}$/.test(normalizedSearchTerm) || /^\+254/.test(normalizedSearchTerm)) {
      setLoadingSearch(false);
    } else {
      setLoadingSearch(true);
    }
  };
  if (loading) {
    return <div className="justify-content-center">Loading...</div>;
  }

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      onFieldChange={handleFieldChangeInternal}
      title="Create Customer Group"
      subtitle="Create a new CustomerGroup record"
    />
  );
};

export default GroupMembersForm;
