import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  customerservice,
  loanManagementUrl,
  setupManagementUrl,
} from "../../config";
import {
  executeAnyMutation,
  fetchJson,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";
import CustomerModal from "./CustomerListModal";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { CircularProgress } from "@mui/material";


const BookGroupLoan = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState("");
  const [customers, setCustomers] = useState("");
  const [open, setOpen] = useState(false);
  const [GroupData, setGroupData] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [totalLoanLimit, setTotalLoanLimit] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleOpen = () => setOpen(true);

  const resetForm = () => {
    setSearchTerm("");
    setFilteredCustomer(null);
    setAmount("");
    setLoanProductSelected("");
  };
  useEffect(() => {
    // Delay for live search to avoid rapid searches
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        setLoadingSearch(true); // Start loading for any input
  
        if (searchTerm.length >= 2) {
          // Perform the search only when input is 4 or more characters
          const group = GroupData.find((cust) => {
            const groupCode = cust.GroupCode?.toString().toLowerCase(); // Ensure string and normalize to lowercase
            const search = searchTerm.toLowerCase(); // Normalize input to lowercase
            return groupCode && groupCode.includes(search);
          });
  
          setFilteredCustomer(group || null);
  
          if (!group) {
            setLoadingSearch(false); // Stop loading if no match found
            Swal.fire("Group Not Found", "Group does not exist", "warning");
          } else {
            const fetchData = async () => {
              try {
                const parameters = {
                  groupId: group.Id,
                };
                const customerByGroup = await fetchJson({
                  objectName: "customerGroupViewByColumnValues",
                  inputObjectName: "customerGroupView",
                  params: parameters,
                  baseUrl: customerservice.uri,
                });
                const allCustomers = JSON.parse(customerByGroup.results) || [];
                setCustomers(allCustomers);
              } catch (error) {
                Swal.fire(
                  "Error",
                  "An error occurred while fetching customer group data.",
                  "error"
                );
              } finally {
                setLoadingSearch(false); // Ensure spinner stops after fetch or error
              }
            };
            fetchData();
          }
        }
      } else {
        // Clear state when input is cleared
        setFilteredCustomer(null);
        setLoadingSearch(false);
      }
    }, 1000); // Adjust delay as necessary (e.g., 500ms)
  
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, GroupData]); // Ensure GroupData dependency is included
  
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const groupResponse = await fetchJsonDynamic(
        "allGroupsView",
        null,
        null,
        customerservice.uri
      );
      const allGroups = JSON.parse(groupResponse.results) || [];

      const params = {companyId: companyId};
      const productResponse = await fetchJsonDynamic(
        "allProductsByColumnValues",
        "parameters",
        params,
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];
      // Filter regions based on selected company ID

      setGroupData(allGroups);
      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);
  const handleAppraisalSubmit = async () => {
    try {
      const mutationData = {
        p_GroupId: filteredCustomer.Id,
        p_ProductId: loanProductSelected,
      };

      const response = await executeAnyMutation(
        "insertGroupLoanApplications",
        mutationData,
        "loanApplication",
        false,
        loanManagementUrl.uri
      );
      const parsedResults = JSON.parse(response.results);
      if (parsedResults) {
        if (parsedResults === "Loan Booked Successfully") {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: parsedResults,
          });
          // Reset form upon successful submission
          resetForm();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: parsedResults,
          });
        }
      } else if (response.errors) {
        throw new Error(
          response.errors[0].Error || "An internal server error occurred."
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
      // Reset form upon successful submission
      resetForm();
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Book A Group Loan</Typography>
      <TextField
        label="Search Group by Group Code"
        placeholder="Enter Group Code"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        //onChange={handleSearchChange}
        onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  endAdornment: loadingSearch && (
                    <CircularProgress size={24} sx={{ marginRight: 2 }} />
                  ),
                }}
      />

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Group Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                 <Grid item xs={12} sm={6}>
                 <Typography>
                    <strong>Group Name:</strong> {filteredCustomer.GroupName}
                  </Typography>
                  <Typography>
                    <strong>Mobile Number:</strong>{" "}
                    {filteredCustomer.PhoneNumber}
                  </Typography>
                  <Typography>
                    <strong>Branch:</strong> {filteredCustomer.Branch}
                  </Typography>
                </Grid> 
                <Grid item xs={12} sm={6}>
               
                  <Typography>
                    <strong>Field Officer:</strong> {filteredCustomer.FirstName}{" "}
                    {filteredCustomer.LastName}{" "}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer.CreatedDate}
                  </Typography>
                  <Typography>
                    <strong>Group Loan Limit:</strong> 10,000
                  </Typography>
                  <Button 
                    variant="contained"
                    size="sm"
                    sx={{
                      backgroundColor: colors.greenAccent[500],
                      borderRadius: "4px",
                    }}
                  onClick={handleOpen}>
                    <Typography>View Customer List</Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Loan Product</InputLabel>
                    <Select
                      value={loanProductSelected}
                      onChange={(e) => setLoanProductSelected(e.target.value)}
                      defaultValue=""
                    >
                      <MenuItem value="" disabled>
                        Select Loan Product
                      </MenuItem>
                      {loanProduct.map((company) => (
                        <MenuItem key={company.Id} value={company.Id}>
                          {company.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleAppraisalSubmit}
          >
            Submit For RO Appraisal
          </Button>
        </>
      )}
      {open && (
        <CustomerModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          customerData={customers}
        />
      )}
    </Box>
  );
};

export default BookGroupLoan;
